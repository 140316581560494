import produce from 'immer';
import { Reducer } from 'redux';

import { CustomerProjectRisksActions, CustomerProjectRiskState } from './types';


const {
  GET_ALL_RISKS_CUSTOMER_PROJECT_REQUEST,
  GET_ALL_RISKS_CUSTOMER_PROJECT_SUCCESS,
  GET_ALL_RISKS_CUSTOMER_PROJECT_FAILURE,
  GET_RISKS_CUSTOMER_PROJECT_DETAIL_REQUEST,
  GET_RISKS_CUSTOMER_PROJECT_DETAIL_SUCCESS,
  GET_RISKS_CUSTOMER_PROJECT_DETAIL_FAILURE,
  DELETE_RISK_CUSTOMER_PROJECT_EVIDENCES_REQUEST,
  DELETE_RISK_CUSTOMER_PROJECT_EVIDENCES_SUCCESS,
  DELETE_RISK_CUSTOMER_PROJECT_EVIDENCES_FAILURE,
  GET_ALL_RISKS_MATRIX_CUSTOMER_PROJECT_REQUEST,
  GET_ALL_RISKS_MATRIX_CUSTOMER_PROJECT_SUCCESS,
  GET_ALL_RISKS_MATRIX_CUSTOMER_PROJECT_FAILURE,
  GET_ALL_EVIDENCES_REQUEST,
  GET_ALL_EVIDENCES_SUCCESS,
  GET_ALL_EVIDENCES_FAILURE,
} = CustomerProjectRisksActions;

const initialState: CustomerProjectRiskState = {
  allRisksCustomerProjects: {
    id: '',
    name: '',
    is_active: false,
    project_status: '',
    last_update_risks_matrix: '',
    risks: []
  },
  allEvidences: {
    evidences: [],
  },
  riskMatrixCustomerProject: {
    last_update_risks_matrix: '',
    risks_matrix: [],
  },
  riskCustomerProjectDetail: {
    activities_origin: [],
    answers_origin: [],
    awareness: {
      alternative: '',
      id: '',
      subtype: '',
      type: '',
      variable: '',
      weight: 0
    },
    checklists_origin: [],
    comment: '',
    controls_customer_project: [],
    damages: [],
    description: '',
    evidences: [],
    ease_of_discovery: {
      alternative: '',
      id: '',
      subtype: '',
      type: '',
      variable: '',
      weight: 0
    },
    ease_of_exploit: {
      alternative: '',
      id: '',
      subtype: '',
      type: '',
      variable: '',
      weight: 0
    },
    financial_damage: {
      alternative: '',
      id: '',
      subtype: '',
      type: '',
      variable: '',
      weight: 0
    },
    friendly_id: '',
    id: '',
    impact_category: '',
    impact_score: 0,
    inherent_risk: '',
    inherent_risk_score: 0,
    instrusion_detection: {
      alternative: '',
      id: '',
      subtype: '',
      type: '',
      variable: '',
      weight: 0
    },
    is_active: false,
    justification_of_inactivation: '',
    loff_of_accountability: {
      alternative: '',
      id: '',
      subtype: '',
      type: '',
      variable: '',
      weight: 0
    },
    loff_of_availability: {
      alternative: '',
      id: '',
      subtype: '',
      type: '',
      variable: '',
      weight: 0
    },
    loss_of_confidentiality: {
      alternative: '',
      id: '',
      subtype: '',
      type: '',
      variable: '',
      weight: 0
    },
    loss_of_integrity: {
      alternative: '',
      id: '',
      subtype: '',
      type: '',
      variable: '',
      weight: 0
    },
    motive: {
      alternative: '',
      id: '',
      subtype: '',
      type: '',
      variable: '',
      weight: 0
    },
    name: '',
    non_compliance: {
      alternative: '',
      id: '',
      subtype: '',
      type: '',
      variable: '',
      weight: 0
    },
    opportunity: {
      alternative: '',
      id: '',
      subtype: '',
      type: '',
      variable: '',
      weight: 0
    },
    privacy_violation: {
      alternative: '',
      id: '',
      subtype: '',
      type: '',
      variable: '',
      weight: 0
    },
    probability_category: '',
    probability_score: 0,
    questionnaires_origin: [],
    questions_origin: [],
    reputation_damage: {
      alternative: '',
      id: '',
      subtype: '',
      type: '',
      variable: '',
      weight: 0
    },
    size: {
      alternative: '',
      id: '',
      subtype: '',
      type: '',
      variable: '',
      weight: 0
    },
    skill_level: {
      alternative: '',
      id: '',
      subtype: '',
      type: '',
      variable: '',
      weight: 0
    },
    stages: [],
    threats: [],
    vulnerabilities: [],
    risk_factors: []
  },
};

export const riskCustomerProjectReducer: Reducer<CustomerProjectRiskState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_RISKS_CUSTOMER_PROJECT_REQUEST: {
        break;
      }

      case GET_ALL_RISKS_CUSTOMER_PROJECT_SUCCESS: {
        const { customerProjectRisk } = action.payload;

        draft.allRisksCustomerProjects = customerProjectRisk;
        break;
      }

      case GET_ALL_RISKS_CUSTOMER_PROJECT_FAILURE: {
        draft.allRisksCustomerProjects = {
          id: '',
          name: '',
          is_active: false,
          project_status: '',
          last_update_risks_matrix: '',
          risks: []
        };
        break;
      }

      case DELETE_RISK_CUSTOMER_PROJECT_EVIDENCES_REQUEST: {
        break;
      }

      case DELETE_RISK_CUSTOMER_PROJECT_EVIDENCES_SUCCESS: {
        const { response } = action.payload;

        draft.riskCustomerProjectDetail.evidences = response.evidences;
        break;
      }

      case DELETE_RISK_CUSTOMER_PROJECT_EVIDENCES_FAILURE: {

        break;
      }


      case GET_RISKS_CUSTOMER_PROJECT_DETAIL_REQUEST: {
        break;
      }

      case GET_RISKS_CUSTOMER_PROJECT_DETAIL_SUCCESS: {
        const { riskCustomerProjectDetail } = action.payload;

        draft.riskCustomerProjectDetail = riskCustomerProjectDetail;
        break;
      }

      case GET_RISKS_CUSTOMER_PROJECT_DETAIL_FAILURE: {
        draft.riskCustomerProjectDetail = {
          activities_origin: [],
          answers_origin: [],
          awareness: {
            alternative: '',
            id: '',
            subtype: '',
            type: '',
            variable: '',
            weight: 0
          },
          checklists_origin: [],
          comment: '',
          controls_customer_project: [],
          damages: [],
          description: '',
          evidences: [],
          ease_of_discovery: {
            alternative: '',
            id: '',
            subtype: '',
            type: '',
            variable: '',
            weight: 0
          },
          ease_of_exploit: {
            alternative: '',
            id: '',
            subtype: '',
            type: '',
            variable: '',
            weight: 0
          },
          financial_damage: {
            alternative: '',
            id: '',
            subtype: '',
            type: '',
            variable: '',
            weight: 0
          },
          vulnerabilities: [],
          risk_factors: [],
          friendly_id: '',
          id: '',
          impact_category: '',
          impact_score: 0,
          inherent_risk: '',
          inherent_risk_score: 0,
          instrusion_detection: {
            alternative: '',
            id: '',
            subtype: '',
            type: '',
            variable: '',
            weight: 0
          },
          is_active: false,
          justification_of_inactivation: '',
          loff_of_accountability: {
            alternative: '',
            id: '',
            subtype: '',
            type: '',
            variable: '',
            weight: 0
          },
          loff_of_availability: {
            alternative: '',
            id: '',
            subtype: '',
            type: '',
            variable: '',
            weight: 0
          },
          loss_of_confidentiality: {
            alternative: '',
            id: '',
            subtype: '',
            type: '',
            variable: '',
            weight: 0
          },
          loss_of_integrity: {
            alternative: '',
            id: '',
            subtype: '',
            type: '',
            variable: '',
            weight: 0
          },
          motive: {
            alternative: '',
            id: '',
            subtype: '',
            type: '',
            variable: '',
            weight: 0
          },
          name: '',
          non_compliance: {
            alternative: '',
            id: '',
            subtype: '',
            type: '',
            variable: '',
            weight: 0
          },
          opportunity: {
            alternative: '',
            id: '',
            subtype: '',
            type: '',
            variable: '',
            weight: 0
          },
          privacy_violation: {
            alternative: '',
            id: '',
            subtype: '',
            type: '',
            variable: '',
            weight: 0
          },
          probability_category: '',
          probability_score: 0,
          questionnaires_origin: [],
          questions_origin: [],
          reputation_damage: {
            alternative: '',
            id: '',
            subtype: '',
            type: '',
            variable: '',
            weight: 0
          },
          size: {
            alternative: '',
            id: '',
            subtype: '',
            type: '',
            variable: '',
            weight: 0
          },
          skill_level: {
            alternative: '',
            id: '',
            subtype: '',
            type: '',
            variable: '',
            weight: 0
          },
          stages: [],
          threats: []
        };
        break;
      }

      case GET_ALL_RISKS_MATRIX_CUSTOMER_PROJECT_REQUEST: {
        break;
      }

      case GET_ALL_RISKS_MATRIX_CUSTOMER_PROJECT_SUCCESS: {
        const { customerProjectRiskMatrix } = action.payload;
        draft.riskMatrixCustomerProject = customerProjectRiskMatrix;
        break;
      }

      case GET_ALL_RISKS_MATRIX_CUSTOMER_PROJECT_FAILURE: {
        draft.riskMatrixCustomerProject = {
          last_update_risks_matrix: '',
          risks_matrix: []
        };
        break;
      }

      case GET_ALL_EVIDENCES_REQUEST: {
        break;
      }

      case GET_ALL_EVIDENCES_SUCCESS: {
        const { evidences } = action.payload;
        draft.allEvidences = evidences;
        break;
      }

      case GET_ALL_EVIDENCES_FAILURE: {
        draft.allEvidences = {
          evidences: []
        };
        break;
      }

      default: {
        return draft;
      }
    }
  });
};
