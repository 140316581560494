import produce from 'immer';
import { Reducer } from 'redux';

import {
  CustomerProjectNistState,
  CustomerProjectNistActions,
} from './type';

const {
  GET_CUSTOMER_PROJECT_NIST_REQUEST,
  GET_CUSTOMER_PROJECT_NIST_SUCCESS,
  GET_CUSTOMER_PROJECT_NIST_FAILURE,
} = CustomerProjectNistActions;

const initialState: CustomerProjectNistState = {
  customerProjectNist: {
    csf_core: {},
    framework: {
      id: '',
      name: '',
      version: '',
      is_active: false,
    },
    csfcoreOrdering: [],
  }
};

export const customerProjectNistReducer: Reducer<CustomerProjectNistState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_CUSTOMER_PROJECT_NIST_REQUEST: {
        break;
      }

      case GET_CUSTOMER_PROJECT_NIST_SUCCESS: {
        const { customerProjectNist } = action.payload;
        draft.customerProjectNist = customerProjectNist;
        break;
      }

      case GET_CUSTOMER_PROJECT_NIST_FAILURE: {
        draft.customerProjectNist = {
          csf_core: {},
          framework: {
            id: '',
            name: '',
            version: '',
            is_active: false,
          },
          csfcoreOrdering: [],
        };
        break;
      }

      default: {
        return draft;
      }
    }
  });
};