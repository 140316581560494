import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import type { Action } from 'store/types';

import { controlRecommendationsActionsFunctions } from './actions';
import {
  ControlsRiskDetail,
  ControlRecommendations,
  ControlRecommendationsActions,
  ControlRecommendationsApiPayload,
  ControlRecommendationsDetailApiPayload,
  ControlCustomerRecommendationsApiPayload,
  ControlCustomerRecommendations,
  ControlRecommendationsChangeStatusApiPayload,
  ControlRecommendationsChangeRankingPhaseApiPayload,
} from './types';

const {
  GET_CONTROL_RECOMMENDATIONS_REQUEST,
  GET_CONTROL_RECOMMENDATIONS_DETAIL_REQUEST,
  EDIT_CONTROL_RECOMMENDATIONS_STATUS_REQUEST,
  GET_CONTROL_CUSTOMER_RECOMMENDATIONS_REQUEST,
  GET_GENERATE_PRICING_RECOMMENDATIONS_REQUEST,
  EDIT_CONTROL_RECOMMENDATIONS_RANKING_PHASE_REQUEST,
  GENERATE_CONTROL_RECOMMENDATIONS_CUSTOMER_PROJECT_REQUEST,
  GET_GENERATE_PHASES_RECOMMENDATIONS_REQUEST,
} = ControlRecommendationsActions;

const {
  getControlRecommendationsRequest,
  getControlRecommendationsSuccess,
  getControlRecommendationsFailure,
  getControlRecommendationsDetailSuccess,
  getControlRecommendationsDetailFailure,
  editControlRecommendationsStatusSuccess,
  editControlRecommendationsStatusFailure,
  editControlRecommendationsRankingPhaseSuccess,
  editControlRecommendationsRankingPhaseFailure,
  generateControlRecommendationsCustomerProjectSuccess,
  generateControlRecommendationsCustomerProjectFailure,
  getControlCustomerRecommendationsRequest,
  getControlCustomerRecommendationsSuccess,
  getControlCustomerRecommendationsFailure,
  getGeneratePhasesRecommendationsSuccess,
  getGeneratePhasesRecommendationsFailure,
  postGeneratePricingRecommendationsSuccess,
  postGeneratePricingRecommendationsFailure,
} = controlRecommendationsActionsFunctions;

const {
  getLastApiFetchDataSuccess,
  getLastApiFetchDataFailure
} = lastApiFetchDataActionsFunctions;

const {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE
} = ToastMessages;

function* fetchControlRecommendationsRequest(action: Action): Generator {
  const { customerProjectId } = action.payload as ControlRecommendationsApiPayload;

  try {
    const controlRecommendationsResponse: AxiosResponse<ControlRecommendations> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${customerProjectId}/recommended-controls/`,
      {}
    );

    const {
      data: controlsRisk,
      config: { url },
      status,
      statusText,
    } = controlRecommendationsResponse as AxiosResponse<ControlRecommendations>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getControlRecommendationsSuccess(controlsRisk));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getControlRecommendationsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* editControlRecommendationsStatusRequest(action: Action): Generator {
  const { controlId, changeStatusBody, customerProjectId } = action.payload as ControlRecommendationsChangeStatusApiPayload;

  try {
    const controlRecommendationsResponse: AxiosResponse<ControlRecommendations> | unknown = yield call(
      api,
      'PUT',
      `/controls/control-risk-customer-project/${controlId}/update-status/`,
      { ...changeStatusBody }
    );

    const {
      config: { url },
      status,
      statusText,
    } = controlRecommendationsResponse as AxiosResponse<ControlRecommendations>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editControlRecommendationsStatusSuccess());
    yield put(getControlCustomerRecommendationsRequest(customerProjectId));

    toast.success('Status alterado com sucesso.');

  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editControlRecommendationsStatusFailure());

    toast.success('Erro ao alterar o status.');
  }
}

function* fetchControlRecommendationsDetailProject(action: Action): Generator {
  const { controlId } = action.payload as ControlRecommendationsDetailApiPayload;

  try {
    const controlsRiskDetailResponse: AxiosResponse<ControlsRiskDetail> | unknown = yield call(
      api,
      'GET',
      `/controls/control-risk-customer-project/${controlId}/detail/`,
      {}
    );

    const {
      data: controlsRiskDetail,
      config: { url },
      status,
      statusText,
    } = controlsRiskDetailResponse as AxiosResponse<ControlsRiskDetail>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getControlRecommendationsDetailSuccess(controlsRiskDetail));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getControlRecommendationsDetailFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* generateControlRecommendationsCustomersProject(action: Action): Generator {
  const { customerProjectId } = action.payload as ControlRecommendationsApiPayload;

  try {
    const controlRecommendationsResponse: AxiosResponse<ControlRecommendations> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${customerProjectId}/generate-recommended-controls/`,
      {}
    );

    const {
      config: { url },
      status,
      statusText,
    } = controlRecommendationsResponse as AxiosResponse<ControlRecommendations>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    if (status === 200 || status === 201) {
      yield put(generateControlRecommendationsCustomerProjectSuccess());
      yield put(getControlCustomerRecommendationsRequest(customerProjectId));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(generateControlRecommendationsCustomerProjectFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
      DEFAULT_ERROR_MESSAGE
    );
  }
}

function* editControlRecommendationsRankingPhasesRequest(action: Action): Generator {
  const { controlId, changeRankingPhaseBody } = action.payload as ControlRecommendationsChangeRankingPhaseApiPayload;

  try {
    const controlRecommendationsResponse: AxiosResponse<ControlRecommendations> | unknown = yield call(
      api,
      'PUT',
      `/controls/control-risk-customer-project/${controlId}/update-position-stage/`,
      { ...changeRankingPhaseBody }
    );

    const {
      config: { url },
      status,
      statusText,
    } = controlRecommendationsResponse as AxiosResponse<ControlRecommendations>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editControlRecommendationsRankingPhaseSuccess());
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editControlRecommendationsRankingPhaseFailure());

    toast.success('Erro ao alterar a posição do controle.');
  }
}

function* fetchControlCustomerRecommendationsRequest(action: Action): Generator {
  const { projectId } = action.payload as ControlCustomerRecommendationsApiPayload;

  try {
    const controlRecommendationsResponse: AxiosResponse<ControlCustomerRecommendations> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${projectId}/recommended-controls-static/`,
      {}
    );

    const {
      data: controlCustomerRecommendations,
      config: { url },
      status,
      statusText,
    } = controlRecommendationsResponse as AxiosResponse<ControlCustomerRecommendations>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getControlCustomerRecommendationsSuccess(controlCustomerRecommendations));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getControlCustomerRecommendationsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchGeneratePhasesRecommendationsRequest(action: Action): Generator {
  const { projectId } = action.payload as ControlCustomerRecommendationsApiPayload;

  try {
    const generatePhaseRecommendationsResponse: AxiosResponse<ControlRecommendations> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${projectId}/generate-phases-controls/`,
      {}
    );

    const {
      config: { url },
      status,
      statusText,
    } = generatePhaseRecommendationsResponse as AxiosResponse<ControlRecommendations>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    if (status === 200 || status === 201) {
      yield put(getGeneratePhasesRecommendationsSuccess());
      yield put(getControlCustomerRecommendationsRequest(projectId));
      toast.success('Geração de fases concluída com sucesso!');
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getGeneratePhasesRecommendationsFailure());

    toast.error('Erro ao executar a geração de fases');
  }
}

function* fetchGeneratePricingRecommendationsRequest(action: Action): Generator {
  const { projectId } = action.payload as ControlCustomerRecommendationsApiPayload;

  try {
    const generatePhaseRecommendationsResponse: AxiosResponse<ControlRecommendations> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${projectId}/generate-pricing-controls/`,
      {}
    );

    const {
      config: { url },
      status,
      statusText,
    } = generatePhaseRecommendationsResponse as AxiosResponse<ControlRecommendations>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    if (status === 200 || status === 201) {
      yield put(postGeneratePricingRecommendationsSuccess());
      yield put(getControlCustomerRecommendationsRequest(projectId));
      toast.success('Geração de precificação concluída com sucesso!');
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(postGeneratePricingRecommendationsFailure());

    toast.error('Erro ao executar a geração de precificação');
  }
}

export function* controlRecommendationsSagas() {
  yield all([
    takeLatest(GET_CONTROL_RECOMMENDATIONS_REQUEST, fetchControlRecommendationsRequest),
    takeLatest(GET_CONTROL_RECOMMENDATIONS_DETAIL_REQUEST, fetchControlRecommendationsDetailProject),
    takeLatest(EDIT_CONTROL_RECOMMENDATIONS_STATUS_REQUEST, editControlRecommendationsStatusRequest),
    takeLatest(GET_GENERATE_PHASES_RECOMMENDATIONS_REQUEST, fetchGeneratePhasesRecommendationsRequest),    
    takeLatest(GET_CONTROL_CUSTOMER_RECOMMENDATIONS_REQUEST, fetchControlCustomerRecommendationsRequest),    
    takeLatest(GET_GENERATE_PRICING_RECOMMENDATIONS_REQUEST, fetchGeneratePricingRecommendationsRequest),
    takeLatest(EDIT_CONTROL_RECOMMENDATIONS_RANKING_PHASE_REQUEST, editControlRecommendationsRankingPhasesRequest),
    takeLatest(GENERATE_CONTROL_RECOMMENDATIONS_CUSTOMER_PROJECT_REQUEST, generateControlRecommendationsCustomersProject),    
  ]);
}