import type { Action } from 'store/types';

import {
  CustomerProjectNist,
  CustomerProjectNistActions,
  CustomerProjectNistEvidences,
} from './type';

const {
  SEND_EVIDENCES_NIST_REQUEST,
  SEND_EVIDENCES_NIST_SUCCESS,
  SEND_EVIDENCES_NIST_FAILURE,
  DELETE_EVIDENCES_NIST_REQUEST,
  DELETE_EVIDENCES_NIST_SUCCESS,
  DELETE_EVIDENCES_NIST_FAILURE,
  GET_EVIDENCES_DOWNLOAD_REQUEST,
  GET_EVIDENCES_DOWNLOAD_SUCCESS,
  GET_EVIDENCES_DOWNLOAD_FAILURE,
  GET_CUSTOMER_PROJECT_NIST_REQUEST,
  GET_CUSTOMER_PROJECT_NIST_SUCCESS,
  GET_CUSTOMER_PROJECT_NIST_FAILURE,
} = CustomerProjectNistActions;

function getCustomersProjectNISTQuestionnaireRequest(
  customerProjectId: string
): Action {
  return {
    type: GET_CUSTOMER_PROJECT_NIST_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function getCustomersProjectNISTQuestionnaireSuccess(
  customerProjectNist: CustomerProjectNist
): Action {
  return {
    type: GET_CUSTOMER_PROJECT_NIST_SUCCESS,
    payload: {
      customerProjectNist
    }
  };
}

function getCustomersProjectNISTQuestionnaireFailure(): Action {
  return {
    type: GET_CUSTOMER_PROJECT_NIST_FAILURE,
  };
}

function getCustomersProjectNISTEvidenceDownloadRequest(
    evidenceId: string, 
    evidenceName: string
  ): Action {
  return {
    type: GET_EVIDENCES_DOWNLOAD_REQUEST,
    payload: {
      evidenceId,
      evidenceName
    }
  };
}
 
function getCustomersProjectNISTEvidenceDownloadSuccess(): Action {
  return {
    type: GET_EVIDENCES_DOWNLOAD_SUCCESS,
  };
}

function getCustomersProjectNISTEvidenceDownloadFailure(): Action {
  return {
    type: GET_EVIDENCES_DOWNLOAD_FAILURE,
  };
}

function sendCustomersProjectNISTEvidencesRequest(
  customerProjectId: string,
  csfcoreProjectId: string,
  evidences: CustomerProjectNistEvidences,
): Action {
  return {
    type: SEND_EVIDENCES_NIST_REQUEST,
    payload: {
      customerProjectId,
      csfcoreProjectId,
      evidences
    }
  };
}

function sendCustomersProjectNISTEvidencesSuccess(): Action {
  return {
    type: SEND_EVIDENCES_NIST_SUCCESS,
  };
}

function sendCustomersProjectNISTEvidencesFailure(): Action {
  return {
    type: SEND_EVIDENCES_NIST_FAILURE,
  };
}

function deleteCustomersProjectNISTEvidencesRequest(
  evidenceId: string
): Action {
  return {
    type: DELETE_EVIDENCES_NIST_REQUEST,
    payload: {
      evidenceId,
    }
  };
}

function deleteCustomersProjectNISTEvidencesSuccess(): Action {
  return {
    type: DELETE_EVIDENCES_NIST_SUCCESS,
  };
}

function deleteCustomersProjectNISTEvidencesFailure(): Action {
  return {
    type: DELETE_EVIDENCES_NIST_FAILURE,
  };
}

export const customerProjectNistActionsFunctions = {
  sendCustomersProjectNISTEvidencesRequest,
  sendCustomersProjectNISTEvidencesSuccess,
  sendCustomersProjectNISTEvidencesFailure,
  deleteCustomersProjectNISTEvidencesRequest,
  deleteCustomersProjectNISTEvidencesSuccess,
  deleteCustomersProjectNISTEvidencesFailure,
  getCustomersProjectNISTQuestionnaireRequest,
  getCustomersProjectNISTQuestionnaireSuccess,
  getCustomersProjectNISTQuestionnaireFailure,
  getCustomersProjectNISTEvidenceDownloadRequest,
  getCustomersProjectNISTEvidenceDownloadSuccess,
  getCustomersProjectNISTEvidenceDownloadFailure,  
};