/* eslint-disable no-plusplus */
import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import type { Action } from 'store/types';
import { returnFileNameWithExtension } from 'utils/functions';

import { customerProjectNistActionsFunctions } from './actions';
import { 
  CustomerProjectNist, 
  CustomerProjectNistActions, 
  CustomerProjectNistEvidenceIdApiPayload, 
  CustomerProjectNistEvidencesPayload, 
  CustomerProjectNistQuestionnaireIdPayload, 
  DeleteCustomerProjectNistEvidencePayload
} from './type';

const {
  SEND_EVIDENCES_NIST_REQUEST,
  DELETE_EVIDENCES_NIST_REQUEST,
  GET_EVIDENCES_DOWNLOAD_REQUEST,
  GET_CUSTOMER_PROJECT_NIST_REQUEST,
} = CustomerProjectNistActions;

const {
  sendCustomersProjectNISTEvidencesSuccess,
  sendCustomersProjectNISTEvidencesFailure,
  deleteCustomersProjectNISTEvidencesSuccess,
  deleteCustomersProjectNISTEvidencesFailure,
  getCustomersProjectNISTQuestionnaireRequest,
  getCustomersProjectNISTQuestionnaireSuccess,
  getCustomersProjectNISTQuestionnaireFailure,
  getCustomersProjectNISTEvidenceDownloadSuccess,
  getCustomersProjectNISTEvidenceDownloadFailure,
} = customerProjectNistActionsFunctions;

const {
  getLastApiFetchDataSuccess,
  getLastApiFetchDataFailure
} = lastApiFetchDataActionsFunctions;

const {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE
} = ToastMessages;

function* fetchCustomersProjectsNistQuestionnairesRequest(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjectNistQuestionnaireIdPayload;

  try {
    const customerProjectNistResponse: AxiosResponse<CustomerProjectNist> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${customerProjectId}/csf-core-questionnaire/`,
      {}
    );

    const {
      data: customerProjectNist,
      config: { url },
      status,
      statusText,
    } = customerProjectNistResponse as AxiosResponse<CustomerProjectNist>;    

    const formattedCustomerProjectNist: CustomerProjectNist = {
      ...customerProjectNist,
      csfcoreOrdering: Object.entries(customerProjectNist.csf_core).map(([key, _value]) => (key)),
    };

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCustomersProjectNISTQuestionnaireSuccess(formattedCustomerProjectNist));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomersProjectNISTQuestionnaireFailure());
    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* getCustomersProjectNISTEvidencesDownloadRequest(action: Action): Generator {
  const { evidenceId, evidenceName } = action.payload as CustomerProjectNistEvidenceIdApiPayload;

  try {
    const evidencesDownloadResponse: AxiosResponse<any> | unknown = yield call(
      api,
      'GET',
      `/controls/evidence-csfcoreproject/${evidenceId}/visualizate/`,
      {},
      'image/*',
      'blob'
    );

    const {
      data: evidence,
      config: { url },
      status,
      statusText,
    } = evidencesDownloadResponse as AxiosResponse<any>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    const outputFilename = returnFileNameWithExtension(evidenceName, evidence.type);

    const fileUrl = URL.createObjectURL(new Blob([evidence], { type: evidence.type }));

    const link = document.createElement('a');
    link.href = fileUrl;

    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();

    toast.success('Download da evidência realizado com sucesso.');

    yield put(getCustomersProjectNISTEvidenceDownloadSuccess());
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomersProjectNISTEvidenceDownloadFailure());
    
    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error('Erro ao fazer o download da evidência.');
    }
  }
}

function* fetchCustomersProjectNISTEvidencesRequest(action: Action): Generator {
  const { customerProjectId, csfcoreProjectId, evidences } = action.payload as CustomerProjectNistEvidencesPayload;
  console.log(action);

  if (!evidences || Object.keys(evidences).length === 0) {
    toast.error('Nenhum arquivo para enviar.');
    return;
  }

  const formData = new FormData();

  Object.values(evidences).flat().forEach((file: File) => {
    if (file?.name) {
      formData.append('file', file, file.name);
    }
  });

  try {
    const evidencesResponse: AxiosResponse<any> | unknown = yield call(
      api,
      'POST',
      `/controls/csf-core-project/${csfcoreProjectId}/upload-evidence/`,
      formData,
      'multipart/form-data'
    );

    const { config: { url }, status, statusText } = evidencesResponse as AxiosResponse<any>;

    yield put(getLastApiFetchDataSuccess({ url, status, statusText }));
    yield put(sendCustomersProjectNISTEvidencesSuccess());
    yield put(getCustomersProjectNISTQuestionnaireRequest(customerProjectId));
    toast.success('Evidências enviadas com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(sendCustomersProjectNISTEvidencesFailure());
    
    if (currentError?.response?.status === 403) {
      yield put(getCustomersProjectNISTQuestionnaireRequest(customerProjectId));
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      yield put(getCustomersProjectNISTQuestionnaireRequest(customerProjectId));
      toast.error('Erro ao enviar a(s) evidência(s).');
    }
  }
}

function* fetchDeleteCustomersProjectNISTEvidenceRequest(action: Action): Generator {
  const { evidenceId } = action.payload as DeleteCustomerProjectNistEvidencePayload;

  try {
    const deleteEvidenceResponse: AxiosResponse<any> | unknown = yield call(
      api,
      'DELETE',
      `controls/evidence-csfcoreproject/${evidenceId}/delete/`,
      {}
    );

    const {
      config: { url },
      status,
      statusText,
    } = deleteEvidenceResponse as AxiosResponse<any>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(deleteCustomersProjectNISTEvidencesSuccess());
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(deleteCustomersProjectNISTEvidencesFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error('Tentativa de deletar a evidência falhou.');
    }
  }
}

export function* customerProjectNistSagas() {
  yield all([
    takeLatest(SEND_EVIDENCES_NIST_REQUEST, fetchCustomersProjectNISTEvidencesRequest),
    takeLatest(DELETE_EVIDENCES_NIST_REQUEST, fetchDeleteCustomersProjectNISTEvidenceRequest),
    takeLatest(GET_EVIDENCES_DOWNLOAD_REQUEST, getCustomersProjectNISTEvidencesDownloadRequest),
    takeLatest(GET_CUSTOMER_PROJECT_NIST_REQUEST, fetchCustomersProjectsNistQuestionnairesRequest),
  ]);
}