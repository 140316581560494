import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import type { Action } from 'store/types';

import { marketAverageActionsFunctions } from './actions';
import { 
  ApiFetchMarketAverageDetailsRequestPayloadParams, 
  MarketAverageActions, 
  MarketAverageDetail, 
  MarketAverageResults, 
  MarketScoreCardAverage, 
  ScoreCardRanking, 
  ScoreCardRankingPayloadParams,
  ScoreCardSectorGraph
} from './types';


const {
  GET_SCORECARD_RANKING_REQUEST,
  GET_ALL_MARKET_AVERAGE_REQUEST,
  GET_MARKET_AVERAGE_DETAILS_REQUEST,
  GET_SCORECARD_SECTOR_GRAPH_REQUEST,
  GET_MARKET_SCORECARD_AVERAGE_REQUEST,  
} = MarketAverageActions;

const {
  getScorecardRankingSuccess,
  getScorecardRankingFailure,
  getMarketAverageDetailSuccess,
  getMarketAverageDetailFailure,
  getMarketAverageSectorsSuccess,
  getMarketAverageSectorsFailure,
  getScoreCardSectorGraphSuccess,
  getScoreCardSectorGraphFailure,
  getMarketScoreCardAverageSuccess,
  getMarketScoreCardAverageFailure,  
} = marketAverageActionsFunctions;

const {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE
} = ToastMessages;

const {
  getLastApiFetchDataSuccess,
  getLastApiFetchDataFailure
} = lastApiFetchDataActionsFunctions;

function* fetchMarketAverageSectorsRequest(): Generator {
  try {
    const marketAverageSectorsResponse: AxiosResponse<MarketAverageResults> | unknown = yield call(
      api,
      'GET',
      '/controls/sectors/',
      {}
    );

    const {
      data: { results, count, next, previous },
      config: { url },
      status,
      statusText,
    } = marketAverageSectorsResponse as AxiosResponse<MarketAverageResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getMarketAverageSectorsSuccess(results, count, next, previous));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getMarketAverageSectorsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchMarketScoreCardAverageRequest(): Generator {
  try {
    const marketScoreCardAverageResponse: AxiosResponse<MarketScoreCardAverage> | unknown = yield call(
      api,
      'GET',
      '/controls/market-scorecard-average/',
      {}
    );

    const {
      data: marketScoreCardAverage,
      config: { url },
      status,
      statusText,
    } = marketScoreCardAverageResponse as AxiosResponse<MarketScoreCardAverage>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getMarketScoreCardAverageSuccess(marketScoreCardAverage));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getMarketScoreCardAverageFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchMarketAverageDetailsRequest(action: Action): Generator {
  const { id, type } = action.payload as ApiFetchMarketAverageDetailsRequestPayloadParams;
  const returnEndPoint = ({
    id,
    type
  }: ApiFetchMarketAverageDetailsRequestPayloadParams): string => {
    switch (type) {
      case 'sector':
        return `/controls/sectors/${id}/customers-projects/`;

      case 'subsector':
        return `/controls/subsectors/${id}/customers-projects/`;

      case 'segment':
        return `/controls/segments/${id}/customers-projects/`;

      default:
        return '';
    }
  };

  try {
    const marketAverageDetailsResponse: AxiosResponse<MarketAverageDetail> | unknown = yield call(
      api,
      'GET',
      returnEndPoint({ id, type }),
      {}
    );

    const {
      data: marketAverageDetail,
      config: { url },
      status,
      statusText,
    } = marketAverageDetailsResponse as AxiosResponse<MarketAverageDetail>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getMarketAverageDetailSuccess(marketAverageDetail));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getMarketAverageDetailFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchScorecardRankingRequest(action: Action): Generator {
  const { year, sectorId } = action.payload as ScoreCardRankingPayloadParams;

  const returnEndPoint = ({
    year,
    sectorId
  }: ScoreCardRankingPayloadParams): string => {
    if(sectorId === '')
      return `/controls/scorecard-ranking/${year}/`;
    return `/controls/scorecard-ranking/${year}/${sectorId}/`;
  };

  try {
    const scorecardRankingResponse: AxiosResponse<ScoreCardRanking[]> | unknown = yield call(
      api,
      'GET',
      returnEndPoint({ year, sectorId }),
      {}
    );

    const {
      data: scoreCardRanking,
      config: { url },
      status,
      statusText,
    } = scorecardRankingResponse as AxiosResponse<ScoreCardRanking[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getScorecardRankingSuccess(scoreCardRanking));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getScorecardRankingFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAllScoreCardSectorGraph(): Generator {
  try {
    const scoreCardSectorGraphResponse: AxiosResponse<ScoreCardSectorGraph> | unknown = yield call(
      api,
      'GET',
      '/controls/sectors-graph/',
      {}
    );

    const {
      data: scoreCardSectorGraph,
      config: { url },
      status,
      statusText,
    } = scoreCardSectorGraphResponse as AxiosResponse<ScoreCardSectorGraph>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getScoreCardSectorGraphSuccess(scoreCardSectorGraph));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getScoreCardSectorGraphFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

export function* marketAverageSagas() {
  yield all([
    takeLatest(GET_SCORECARD_RANKING_REQUEST, fetchScorecardRankingRequest),
    takeLatest(GET_ALL_MARKET_AVERAGE_REQUEST, fetchMarketAverageSectorsRequest),
    takeLatest(GET_SCORECARD_SECTOR_GRAPH_REQUEST, fetchAllScoreCardSectorGraph),
    takeLatest(GET_MARKET_AVERAGE_DETAILS_REQUEST, fetchMarketAverageDetailsRequest),
    takeLatest(GET_MARKET_SCORECARD_AVERAGE_REQUEST, fetchMarketScoreCardAverageRequest),        
  ]);
}
