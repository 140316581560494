import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import type { Action } from 'store/types';

import { FrameworksActionsFunctions } from './actions';
import {
  AddNewFrameworkRequestPayload,
  AllFramewoks,
  AllStandards,
  AlternativeIdPayload,
  Alternatives,
  AlternativesEditPayload,
  ApprovedRequestPayloadParams,
  CSFCoreModel,
  CSFCoreRefCreatePayload,
  CSFCoreRefEditPayload,
  CreateAlternativePayload,
  CustomerProjectPayload,
  DeleteItemCSFCoreRefParam,
  Framework,
  FrameworkControlsDetails,
  FrameworkControlsDetailsRequestPayload,
  FrameworkDetails,
  FrameworkDetailsRequestPayload,
  FrameworkEditPayload,
  FrameworkItemsDetailsResponse,
  FrameworkStandardsDetails,
  FrameworkStandardsDetailsRequestPayload,
  FrameworksActions,
  FrameworksControlsPayload,
  FrameworksItemsPayload,
  ItemDetails,
  SearchFilteredFrameworksRequestPayloadParams,
  StandardItemsPayload,
  UpdateStatusRequestPayloadParams,
} from './types';

const {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE
} = ToastMessages;

const {
  EDIT_FRAMEWORKS_REQUEST,
  GET_ALL_FRAMEWORKS_REQUEST,
  ADD_NEW_FRAMEWORKS_REQUEST,
  GET_STANDARDS_ITEMS_REQUEST,
  APPROVED_FRAMEWORKS_REQUEST,
  ADD_STANDARDS_ITEMS_REQUEST,
  GET_FRAMEWORKS_DETAIL_REQUEST,
  CREATE_CSFCOREREF_ITEM_REQUEST,
  ADD_FRAMEWORKS_CONTROLS_REQUEST,
  GET_FILTERED_FRAMEWORKS_REQUEST,
  EDIT_CSFCOREREF_FRAMEWORKS_REQUEST,
  DELETE_CSFCOREREF_FRAMEWORKS_REQUEST,
  EDIT_ALTERNATIVES_FRAMEWORKS_REQUEST,
  ACTIVE_OR_INACTIVE_FRAMEWORKS_REQUEST,
  CREATE_CSFCOREREF_ALTERNATIVE_REQUEST,
  DELETE_CSFCOREREF_ALTERNATIVES_REQUEST,
  GET_FRAMEWORKS_CONTROLS_DETAIL_REQUEST,
  GET_FRAMEWORKS_STANDARDS_DETAIL_REQUEST,
  GET_FRAMEWORKS_ITEMS_DETAIL_REQUEST,
  ADD_FRAMEWORKS_ITEMS_REQUEST
} = FrameworksActions;

const {
  editFrameworkSuccess,
  editFrameworkFailure,
  getFrameworksRequest,
  getFrameworksSuccess,
  getFrameworksFailure,
  addNewFrameworkSuccess,
  addNewFrameworkFailure,
  addStandardItemsSuccess,
  addStandardItemsFailure,
  approvedFrameworksSuccess,
  approvedFrameworksFailure,
  getFrameworkDetailsRequest,
  getFrameworkDetailsSuccess,
  getFrameworkDetailsFailure,
  createCSFCoreRefItemSuccess,
  createCSFCoreRefItemFailure,
  deleteItemCSFCoreRefSuccess,
  deleteItemCSFCoreRefFailure,
  addFrameworksControlsSuccess,
  addFrameworksControlsFailure,
  getFrameworkStandardsSuccess,
  getFrameworkStandardsFailure,
  createAlternativeCSFCoreSuccess,
  createAlternativeCSFCoreFailure,
  deleteAlternativeCSFCoreSuccess,
  deleteAlternativeCSFCoreFailure,
  editAlternativesFrameworkSuccess,
  editAlternativesFrameworkFailure,
  activeOrInactiveFrameworksSuccess,
  activeOrInactiveFrameworksFailure,
  getSearchFilteredFrameworksSuccess,
  getSearchFilteredFrameworksFailure,
  getFrameworkControlsDetailsSuccess,
  getFrameworkControlsDetailsFailure,
  getFrameworkStandardsDetailsSuccess,
  getFrameworkStandardsDetailsFailure,
  getFrameworkItemsDetailsRequest,
  getFrameworkItemsDetailsSuccess,
  getFrameworkItemsDetailsFailure,
  addFrameworksItemsSuccess,
  addFrameworksItemsFailure
} = FrameworksActionsFunctions;

const { getLastApiFetchDataSuccess, getLastApiFetchDataFailure } = lastApiFetchDataActionsFunctions;

function* fetchAllFrameworks(action: Action): Generator {
  const { isCustomerProjectPage } = action.payload as CustomerProjectPayload;
  try {
    const frameworksResponse: AxiosResponse<AllFramewoks> | unknown = yield call(
      api,
      'GET',
      'controls/frameworks/',
      {}
    );

    const {
      data: frameworks,
      config: { url },
      status,
      statusText,
    } = frameworksResponse as AxiosResponse<AllFramewoks>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getFrameworksSuccess(frameworks));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getFrameworksFailure());

    if (currentError?.response?.status === 403) {
      if(!isCustomerProjectPage)
        toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAddFramework(action: Action): Generator {
  const {
    name,
    version,
    publication_date,
    successor
  } = action.payload as AddNewFrameworkRequestPayload;

  try {
    const addNewFrameworkResponse: AxiosResponse<Framework> | unknown = yield call(
      api,
      'POST',
      'controls/frameworks/create/',
      {
        name,
        version,
        publication_date,
        successor
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = addNewFrameworkResponse as AxiosResponse<Framework>;

    yield put(
      getLastApiFetchDataSuccess(
        {
          url,
          status,
          statusText,
        }
      )
    );

    yield put(addNewFrameworkSuccess());
    yield put(getFrameworksRequest());

    toast.success('Novo framework adicionado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(addNewFrameworkFailure());

    toast.error('Tentativa de adicionar um novo framework falhou.');
  }
}

function* fetchToFrameworkEditing(action: Action): Generator {
  const {
    id,
    name,
    version,
    publication_date,
    successor,
    is_active
  } = action.payload as FrameworkEditPayload;

  try {
    const frameworkEditingResponse: AxiosResponse<Framework> | unknown = yield call(
      api,
      'PUT',
      `controls/frameworks/${id}/`,
      {
        name,
        version,
        publication_date,
        successor,
        is_active
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = frameworkEditingResponse as AxiosResponse<Framework>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editFrameworkSuccess());
    yield put(getFrameworksRequest());

    toast.success('Edição do framework foi realizado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editFrameworkFailure());

    toast.error('Tentativa de edição do framework falhou.');
  }
}

function* fetchToUpdateFrameworksStatus(action: Action): Generator {
  const { id, is_active } = action.payload as UpdateStatusRequestPayloadParams;

  try {
    const statusResponse: AxiosResponse<Framework> | unknown =
      yield call(
        api,
        'PUT',
        `controls/frameworks/${id}/update-status/`,
        {
          is_active,
        }
      );

    const {
      config: { url },
      status,
      statusText,
    } = statusResponse as AxiosResponse<Framework>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(activeOrInactiveFrameworksSuccess());
    yield put(getFrameworksRequest());

    toast.success('Edição do status do framework foi realizada com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(activeOrInactiveFrameworksFailure());

    toast.error('Tentativa de edição do status do framework falhou.');
  }
}

function* fetchSearchFilteredFrameworks(action: Action): Generator {
  const { name, is_active } = action.payload as SearchFilteredFrameworksRequestPayloadParams;

  let searchFrameworks = 'controls/frameworks/';
  if (name !== null) {
    searchFrameworks = searchFrameworks.concat(`?search=${name}`);
    if (is_active !== null) {
      searchFrameworks = searchFrameworks.concat(`&&is_active=${is_active}`);
    }
  } else if (is_active !== null) {
    searchFrameworks = searchFrameworks.concat(`?is_active=${is_active}`);
  }

  try {
    const filteredFrameworksResponse: AxiosResponse<AllFramewoks> | unknown = yield call(
      api,
      'GET',
      searchFrameworks,
      {}
    );

    const {
      data: filteredFrameworks,
      config: { url },
      status,
      statusText,
    } = filteredFrameworksResponse as AxiosResponse<AllFramewoks>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredFrameworksSuccess(filteredFrameworks));
    toast.success('Filtragem da pesquisa do(s) framework(s) foi realizada com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredFrameworksFailure());

    toast.error('Tentativa de filtragem da pesquisa do(s) framework(s) da coroa falhou.');
  }
}

function* fetchFrameworkDetails(action: Action): Generator {
  const { id } = action.payload as FrameworkDetailsRequestPayload;

  try {
    const frameworkDetailsResponse: AxiosResponse<FrameworkDetails> | unknown =
      yield call(
        api,
        'GET',
        `controls/frameworks/${id}/detail/`,
        {}
      );

    const {
      data: frameworkDetails,
      config: { url },
      status,
      statusText,
    } = frameworkDetailsResponse as AxiosResponse<FrameworkDetails>;

    const formattedFrameworkDetails: FrameworkDetails = {
      ...frameworkDetails,
      csfcoreOrdering: Object.entries(frameworkDetails.csf_core).map(([key, _value]) => (key)),
    };

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getFrameworkDetailsSuccess(formattedFrameworkDetails));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getFrameworkDetailsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAlternativesFrameworkEditing(action: Action): Generator {
  const {
    id,
    name,
    peso,
    csf_core_ref,
    frameworkId
  } = action.payload as AlternativesEditPayload;

  try {
    const alternativeFrameworkEditingResponse: AxiosResponse<Framework> | unknown = yield call(
      api,
      'PUT',
      `controls/alternative-csf-core-ref/${id}/`,
      {
        name,
        peso,
        csf_core_ref
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = alternativeFrameworkEditingResponse as AxiosResponse<Framework>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editAlternativesFrameworkSuccess());
    yield put(getFrameworkDetailsRequest({ id: frameworkId }));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editAlternativesFrameworkFailure());

    toast.error('Tentativa de edição da alternativa do framework falhou.');
  }
}

function* fetchCSFCoreRefFrameworkEditing(action: Action): Generator {
  const {
    id,
    friendly_id,
    csf_function,
    category_name,
    category_description,
    subcategory,
    subcategory_ptbr,
    version,
    evidence_to_be_collected,
    frameworkId,
    implementation_examples
  } = action.payload as CSFCoreRefEditPayload;

  try {
    const alternativeFrameworkEditingResponse: AxiosResponse<CSFCoreModel> | unknown = yield call(
      api,
      'PUT',
      `controls/csf-core-ref/${id}/`,
      {
        friendly_id,
        csf_function,
        category_name,
        category_description,
        subcategory,
        subcategory_ptbr,
        version,
        evidence_to_be_collected,
        implementation_examples
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = alternativeFrameworkEditingResponse as AxiosResponse<CSFCoreModel>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editAlternativesFrameworkSuccess());
    yield put(getFrameworkDetailsRequest({ id: frameworkId }));
    toast.success('Edição do CSFCoreRef foi realizada com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editAlternativesFrameworkFailure());

    toast.error('Tentativa de edição do CSFCoreRef falhou.');
  }
}

function* fetchToUpdateFrameworksApproved(action: Action): Generator {
  const { id, name, approved, approval_date } = action.payload as ApprovedRequestPayloadParams;

  try {
    const approvedResponse: AxiosResponse<Framework> | unknown =
      yield call(
        api,
        'PUT',
        `controls/frameworks/${id}/update-approved/`,
        {
          id,
          name,
          approved,
          approval_date
        }
      );

    const {
      config: { url },
      status,
      statusText,
    } = approvedResponse as AxiosResponse<Framework>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(approvedFrameworksSuccess());
    yield put(getFrameworksRequest());

    toast.success('Edição da aprovação do framework foi realizada com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(approvedFrameworksFailure());

    toast.error('Tentativa de edição da aprovação do framework falhou.');
  }
}

function* fetchFrameworkStandardsDetails(action: Action): Generator {
  const { id } = action.payload as FrameworkStandardsDetailsRequestPayload;

  try {
    const frameworkStandardsDetailsResponse: AxiosResponse<FrameworkStandardsDetails> | unknown =
      yield call(
        api,
        'GET',
        `controls/csf-core-ref/${id}/detail/`,
        {}
      );

    const {
      data: frameworkStandardsDetails,
      config: { url },
      status,
      statusText,
    } = frameworkStandardsDetailsResponse as AxiosResponse<FrameworkStandardsDetails>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getFrameworkStandardsDetailsSuccess(frameworkStandardsDetails));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getFrameworkStandardsDetailsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAllStandards(): Generator {
  try {
    const standardsResponse: AxiosResponse<AllStandards> | unknown = yield call(
      api,
      'GET',
      'controls/standards-items/',
      {}
    );

    const {
      data: standards,
      config: { url },
      status,
      statusText,
    } = standardsResponse as AxiosResponse<AllStandards>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getFrameworkStandardsSuccess(standards));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getFrameworkStandardsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchToAddStandardItems(action: Action): Generator {
  let statusAdd = 0;
  let statusRemove = 0;

  const {
    id,
    standardItemsAdd,
    standardItemsRemove,
  } = action.payload as StandardItemsPayload;

  try {
    if (standardItemsAdd.length) {
      const standardItemsAddResponse: AxiosResponse<Framework> | unknown = yield call(
        api,
        'PUT',
        `controls/csf-core-ref/${id}/add-standard-items/`,
        {
          standard_items: standardItemsAdd
        }
      );

      const {
        config: { url },
        status,
        statusText,
      } = standardItemsAddResponse as AxiosResponse<Framework>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      statusAdd = status;
    }

    if (standardItemsRemove.length) {
      const standardItemsRemoveResponse: AxiosResponse<Framework> | unknown = yield call(
        api,
        'PUT',
        `controls/csf-core-ref/${id}/remove-standard-items/`,
        {
          standard_items: standardItemsRemove
        }
      );

      const {
        config: { url },
        status,
        statusText,
      } = standardItemsRemoveResponse as AxiosResponse<Framework>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      statusRemove = status;
    }

    if ((statusAdd === 200 || statusAdd === 201) || (statusRemove === 200 || statusRemove === 201)) {
      yield put(addStandardItemsSuccess());
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(addStandardItemsFailure());

    toast.error('Tentativa de edição do framework falhou.');
  }
}

function* fetchFrameworkControlsDetails(action: Action): Generator {
  const { id } = action.payload as FrameworkControlsDetailsRequestPayload;

  try {
    const frameworkControlsDetailsResponse: AxiosResponse<FrameworkControlsDetails> | unknown =
      yield call(
        api,
        'GET',
        `controls/csf-core-ref/${id}/controls-detail/`,
        {}
      );

    const {
      data: frameworkControlsDetails,
      config: { url },
      status,
      statusText,
    } = frameworkControlsDetailsResponse as AxiosResponse<FrameworkControlsDetails>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getFrameworkControlsDetailsSuccess(frameworkControlsDetails));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getFrameworkControlsDetailsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchToAddFrameworksControls(action: Action): Generator {
  let statusAdd = 0;
  let statusRemove = 0;

  const {
    id,
    frameworksControlsAdd,
    frameworksControlsRemove
  } = action.payload as FrameworksControlsPayload;

  try {
    if (frameworksControlsAdd.length) {
      const frameworkControlsAddResponse: AxiosResponse<Framework> | unknown = yield call(
        api,
        'PUT',
        `controls/csf-core-ref/${id}/add-controls/`,
        {
          controls: frameworksControlsAdd
        }
      );

      const {
        config: { url },
        status,
        statusText,
      } = frameworkControlsAddResponse as AxiosResponse<Framework>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      statusAdd = status;
    }

    if (frameworksControlsRemove.length) {
      const frameworkControlsRemoveResponse: AxiosResponse<Framework> | unknown = yield call(
        api,
        'PUT',
        `controls/csf-core-ref/${id}/remove-controls/`,
        {
          controls: frameworksControlsRemove
        }
      );

      const {
        config: { url },
        status,
        statusText,
      } = frameworkControlsRemoveResponse as AxiosResponse<Framework>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      statusRemove = status;
    }

    if ((statusAdd === 200 || statusAdd === 201) || (statusRemove === 200 || statusRemove === 201)) {
      yield put(addFrameworksControlsSuccess());
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(addFrameworksControlsFailure());

    toast.error('Tentativa de edição do framework falhou.');
  }
}

function* fetchDeleteItemCSFCoreRef(action: Action): Generator {
  const { csfCoreRefId, frameworkId } = action.payload as DeleteItemCSFCoreRefParam;

  try {
    const deleteItemResponse: AxiosResponse<any> | unknown = yield call(
      api,
      'DELETE',
      `controls/csf-core-ref/${csfCoreRefId}/delete/`,
      {}
    );

    const {
      config: { url },
      status,
      statusText,
    } = deleteItemResponse as AxiosResponse<any>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(deleteItemCSFCoreRefSuccess());
    toast.success('Item do CSFCoreRef foi deletado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(deleteItemCSFCoreRefFailure());

    if (currentError.response?.data.error_message === 'Cannot delete this instance of CSFCoreRef because it is referenced through protected foreign keys.') {
      yield put(getFrameworkDetailsRequest({ id: frameworkId }));
      toast.error('Não é possível excluir esta instância de CSFCoreRef porque ela é referenciada por meio de chaves estrangeiras protegidas.');
    }
    else {
      toast.error('Tentativa de deletar item do CSFCoreRef falhou.');
    }
  }
}

function* fetchCreateCSFCoreRefItemRequest(action: Action): Generator {
  const {
    friendly_id,
    csf_function,
    category_name,
    category_description,
    subcategory,
    subcategory_ptbr,
    framework,
    evidence_to_be_collected,
    implementation_examples,
    risk_group,
  } = action.payload as CSFCoreRefCreatePayload;

  try {
    const createResponse: AxiosResponse<Framework> | unknown = yield call(
      api,
      'POST',
      'controls/csf-core-ref/create/',
      {
        friendly_id,
        csf_function,
        category_name,
        category_description,
        subcategory,
        subcategory_ptbr,
        framework,
        evidence_to_be_collected,
        implementation_examples,
        risk_group
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = createResponse as AxiosResponse<Framework>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(createCSFCoreRefItemSuccess());
    if (status === 201) {
      toast.success('Item do CSFCoreRef foi criado com sucesso.');
      yield put(getFrameworkDetailsRequest({ id: framework }));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(createCSFCoreRefItemFailure());

    toast.error('Tentativa de criação de um item de CSFCoreRef falhou.');
  }
}

function* fetchDeleteAlternativeCSFCore(action: Action): Generator {
  const { id } = action.payload as AlternativeIdPayload;

  try {
    const deleteAlternativeResponse: AxiosResponse<any> | unknown = yield call(
      api,
      'DELETE',
      `controls/alternative-csf-core-ref/${id}/delete/`,
      {}
    );

    const {
      config: { url },
      status,
      statusText,
    } = deleteAlternativeResponse as AxiosResponse<any>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(deleteAlternativeCSFCoreSuccess());
    toast.success('Alternativa do CSFCoreRef foi deletado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(deleteAlternativeCSFCoreFailure());

    toast.error('Tentativa de deletar alternativa do CSFCoreRef falhou.');
  }
}

function* fetchCreateAlternativeCSFCoreRefRequest(action: Action): Generator {
  const {
    peso,
    name,
    csf_core_ref,
    frameworkId,
  } = action.payload as CreateAlternativePayload;

  try {
    const createAlternativeResponse: AxiosResponse<Alternatives> | unknown = yield call(
      api,
      'POST',
      'controls/alternative-csf-core-ref/create/',
      {
        peso,
        name,
        csf_core_ref
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = createAlternativeResponse as AxiosResponse<Alternatives>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(createAlternativeCSFCoreSuccess());
    if (status === 201) {
      toast.success('Alternativa do CSFCoreRef foi criado com sucesso.');
      yield put(getFrameworkDetailsRequest({ id: frameworkId }));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(createAlternativeCSFCoreFailure());

    toast.error('Tentativa de criação de uma alternativa de CSFCoreRef falhou.');
  }
}

function* fetchFrameworkItemsDetails(action: Action): Generator {
  const {
    id,
  } = action.payload as FrameworkStandardsDetailsRequestPayload;
  try {
    const frameworkItemsDetailsResponse: AxiosResponse<FrameworkItemsDetailsResponse> | unknown =
      yield call(
        api,
        'GET',
        `/controls/frameworks/${id}/list/`,
        {}
      );

    const {
      data: frameworkItemsDetails,
      config: { url },
      status,
      statusText,
    } = frameworkItemsDetailsResponse as AxiosResponse< FrameworkItemsDetailsResponse>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getFrameworkItemsDetailsSuccess(frameworkItemsDetails));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getFrameworkItemsDetailsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchToAddFrameworkItems(action: Action): Generator {
  const {
    id,
    frameworksItems,
    frameworkId
  } = action.payload as FrameworksItemsPayload;

  try {
      const frameworkItemsAddResponse: AxiosResponse<Framework> | unknown = yield call(
        api,
        'PUT',
        `controls/csf-core-ref/${id}/alter-csf-core-ref-items/`,
        {
          csf_core_ref_items: frameworksItems
        }
      );

      const {
        config: { url },
        status,
        statusText,
      } = frameworkItemsAddResponse as AxiosResponse<ItemDetails>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      yield put(addFrameworksItemsSuccess());
      yield put (getFrameworkItemsDetailsRequest({id: frameworkId}));

      toast.success('Edição de vínculos do CSFCoreRef foi realizada com sucesso.');

  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(addFrameworksItemsFailure());

    toast.error('Tentativa de edição do framework falhou.');
  }
}
export function* frameworksSagas() {
  yield all([
    takeLatest(ADD_NEW_FRAMEWORKS_REQUEST, fetchAddFramework),
    takeLatest(GET_ALL_FRAMEWORKS_REQUEST, fetchAllFrameworks),
    takeLatest(GET_STANDARDS_ITEMS_REQUEST, fetchAllStandards),
    takeLatest(EDIT_FRAMEWORKS_REQUEST, fetchToFrameworkEditing),
    takeLatest(GET_FRAMEWORKS_DETAIL_REQUEST, fetchFrameworkDetails),
    takeLatest(ADD_STANDARDS_ITEMS_REQUEST, fetchToAddStandardItems),
    takeLatest(APPROVED_FRAMEWORKS_REQUEST, fetchToUpdateFrameworksApproved),
    takeLatest(ADD_FRAMEWORKS_CONTROLS_REQUEST, fetchToAddFrameworksControls),
    takeLatest(GET_FILTERED_FRAMEWORKS_REQUEST, fetchSearchFilteredFrameworks),
    takeLatest(DELETE_CSFCOREREF_FRAMEWORKS_REQUEST, fetchDeleteItemCSFCoreRef),
    takeLatest(CREATE_CSFCOREREF_ITEM_REQUEST, fetchCreateCSFCoreRefItemRequest),
    takeLatest(EDIT_CSFCOREREF_FRAMEWORKS_REQUEST, fetchCSFCoreRefFrameworkEditing),
    takeLatest(ACTIVE_OR_INACTIVE_FRAMEWORKS_REQUEST, fetchToUpdateFrameworksStatus),
    takeLatest(GET_FRAMEWORKS_CONTROLS_DETAIL_REQUEST, fetchFrameworkControlsDetails),
    takeLatest(DELETE_CSFCOREREF_ALTERNATIVES_REQUEST, fetchDeleteAlternativeCSFCore),
    takeLatest(EDIT_ALTERNATIVES_FRAMEWORKS_REQUEST, fetchAlternativesFrameworkEditing),
    takeLatest(GET_FRAMEWORKS_STANDARDS_DETAIL_REQUEST, fetchFrameworkStandardsDetails),
    takeLatest(CREATE_CSFCOREREF_ALTERNATIVE_REQUEST, fetchCreateAlternativeCSFCoreRefRequest),
    takeLatest(GET_FRAMEWORKS_ITEMS_DETAIL_REQUEST, fetchFrameworkItemsDetails),
    takeLatest(ADD_FRAMEWORKS_ITEMS_REQUEST, fetchToAddFrameworkItems),
  ]);
}
