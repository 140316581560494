export enum MarketAverageActions {
  GET_SCORECARD_RANKING_REQUEST = 'GET_SCORECARD_RANKING_REQUEST',
  GET_SCORECARD_RANKING_SUCCESS = 'GET_SCORECARD_RANKING_SUCCESS',
  GET_SCORECARD_RANKING_FAILURE = 'GET_SCORECARD_RANKING_FAILURE',
  GET_ALL_MARKET_AVERAGE_REQUEST = 'GET_ALL_MARKET_AVERAGE_REQUEST',
  GET_ALL_MARKET_AVERAGE_SUCCESS = 'GET_ALL_MARKET_AVERAGE_SUCCESS',
  GET_ALL_MARKET_AVERAGE_FAILURE = 'GET_ALL_MARKET_AVERAGE_FAILURE',
  GET_MARKET_AVERAGE_DETAILS_REQUEST = 'GET_MARKET_AVERAGE_DETAILS_REQUEST',
  GET_MARKET_AVERAGE_DETAILS_SUCCESS = 'GET_MARKET_AVERAGE_DETAILS_SUCCESS',
  GET_MARKET_AVERAGE_DETAILS_FAILURE = 'GET_MARKET_AVERAGE_DETAILS_FAILURE',
  GET_SCORECARD_SECTOR_GRAPH_REQUEST = 'GET_SCORECARD_SECTOR_GRAPH_REQUEST',
  GET_SCORECARD_SECTOR_GRAPH_SUCCESS = 'GET_SCORECARD_SECTOR_GRAPH_SUCCESS',
  GET_SCORECARD_SECTOR_GRAPH_FAILURE = 'GET_SCORECARD_SECTOR_GRAPH_FAILURE',
  GET_MARKET_SCORECARD_AVERAGE_REQUEST = 'GET_MARKET_SCORECARD_AVERAGE_REQUEST',
  GET_MARKET_SCORECARD_AVERAGE_SUCCESS = 'GET_MARKET_SCORECARD_AVERAGE_SUCCESS',
  GET_MARKET_SCORECARD_AVERAGE_FAILURE = 'GET_MARKET_SCORECARD_AVERAGE_FAILURE',  
}

export type Segment = {
  id: string;
  name: string;
  qtd_projects: number;
  average_final_score: number;
  rating_category: string;
};

export type SubSector = {
  id: string;
  name: string;
  qtd_projects: number;
  average_final_score: number;
  rating_category: string;
  segments: Segment[];
};

export type Sector = {
  id: string;
  name: string;
  qtd_customers: number;
  qtd_projects: number;
  rating_category: string;
  average_final_score: number;
  subsectors: SubSector[];
};

export type MarketScoreCardAverage = {
  average_score_card_rating: string;
  from_number: number;
  to_number: number;
  average_final_score: number;
}

export type MarketAverageProject = {
  id: string;
  name: string;
  first_year: number;
  first_score_card_rating: string;
  last_year: number;
  last_score_card_rating: string;
}

export type AverageScoreCardRating = {
  id: string;
  name: string;
  category: string;
  from: number;
  to: number;
}

export type MarketAverageDetail = {
  id: string;
  name: string;
  average_final_score: number;
  average_score_card_rating: AverageScoreCardRating;
  projects: MarketAverageProject[];
  qtd_customers: number;
  qtd_projects: number;
  qtd_segments?: number;
  qtd_subsectors?: number;
}

export type SectorGraph = {
  id: string;
  name: string;
  qtd_projects: number;
  highest_rating_category: string;
  lowest_rating_category: string;
  highest_final_score: number;
  lowest_final_score: number;
  average_final_score: number;
  rating_category: string;
}

export type ScoreCardSectorGraph = {
  count: number;
  next: string | null;
  previous: string | null;
  results: SectorGraph[];
}

export type ScoreCardRanking = {
  project_id: string;
  customer: string;
  year: number;
  sector: string;
  subsector: string;
  segment: string;
  final_score: number;
  score_card_rating: string;
}

export type MarketAverageState = {
  marketAverageOfAllSectors: Sector[];
  marketScoreCardAverage: MarketScoreCardAverage;
  marketAverageDetail: MarketAverageDetail;
  scoreCardRanking: ScoreCardRanking[];
  scoreCardSectorGraph: ScoreCardSectorGraph;
};

export type MarketAverageResults = {
  results: Sector[];
  count: number;
  next: boolean;
  previous: boolean;
};

export type ApiFetchMarketAverageDetailsRequestPayloadParams = {
  id: string;
  type: string;
}

export type ScoreCardRankingPayloadParams = {
  year: string;
  sectorId: string;
}
