/* eslint-disable no-plusplus */
import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { history } from 'services/history';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import type { Action } from 'store/types';
import {
  ThereIsEvidenceInAnswer,
  ThereIsEvidenceInQuestionCreate,
  ThereIsEvidenceToRemove,
} from 'utils/functions';

import { customerProjectNistActionsFunctions } from '../customerProjectNist/actions';
import {
  CustomerProjetcIdApiPayload,
  CustomerProjetcPDFApproveIdApiPayload,
  sendInterviewEmailApiPayload
} from '../customerProjectRisk/types';
import { customerProjectActionsFunctions } from './actions';
import {
  ActivityEvidencesPayloadToApi,
  ActivityRegistrationsPayloadApi,
  ActivityRegistrationsPayloadApiDetail,
  AnalystAnswerCreateResponse,
  AnalystAnsweringQuestion,
  AnswersEvidencesToRemove,
  AnswersQuestionnaireEvidences,
  ChecklistActivityAnswerPayloadType,
  ChecklistActivityAnswerResponseType,
  ChecklistActivityDetail,
  ChecklistActivityDetailActionPayload,
  ChecklistQuestionAnswerPayloadType,
  ChecklistQuestionAnswerResponseBody,
  ChecklistQuestionDetailActionPayload,
  ControlsAnalystsResults,
  ControlsCustomerResults,
  ControlsProjectTypeResults,
  CustomerProjectActions,
  CustomerProjectChangeStatusApiPayload,
  CustomerProjectDeleteCrownJewelPayload,
  CustomerProjectDetail,
  CustomerProjectEditCrownJewelPayload,
  CustomerProjectEditCSFCorePayload,
  CustomerProjectQuestionnairePayload,
  CustomerProjectResults,
  CustomerProjectsActionToSaga,
  CustomerProjectsChecklists,
  CustomerProjectScoreCard,
  CustomerProjectsDetailActionPayload,
  CustomerProjectsThreats,
  CustomerProjetcDetailApiPayload,
  EditChecklistActivityAnswerPayloadType,
  EditChecklistActivityAnswerResponse,
  EditCustomerProjectRequestPayload,
  EditQuestionnaireAnswerBody,
  EvidencePayloadApiBody,
  OrganogramPayloadApiBody,
  OrganogramType,
  QuestionEvidencesPayloadToApi,
  QuestionnaireInterviwees,
  QuestionnaireInterviweesPayload,
  ReportsBody,
  ReportsText,
  SearchFilteredCustomerProjectRequestPayloadParams,
  CustomerProjectsQuestionnaires,
  NewCustomerProjectsQuestionnaires,
  CustomerProjectQuestionnaireDetailPayload,
  CustomerProjectsQuestionnaireDetails,
  LocalType,
  NewProjectCreate,
  ProjectStatuschangePayloadApi,
  ScoreCardRadarChart,
  RiskDonutChart,
  RiskHeatMap,
  ProjectExpirationDatePayloadApi,
  CustomerProjectPagePayload,
  ProjectsThreats,
  CustomersProjectsCustomer,
  AllEnvAssessmentCustomerProject,
  IdentifiedQuantityPayload,
  LinkAuditedIntervieweesResponsePayload,
  LinkAuditedIntervieweesPayload
} from './types';


const {
  GET_ALL_CUSTOMER_PROJECT_REQUEST,
  CREATE_NEW_CUSTOMER_PROJECT_REQUEST,
  GET_ALL_ANALYSTS_REQUEST,
  GET_ALL_CONTROL_CUSTOMERS_REQUEST,
  GET_ALL_CONTROL_PROJECT_TYPE_REQUEST,
  EDIT_CUSTOMER_PROJECT_REQUEST,
  GET_CUSTOMER_PROJECT_DETAIL_REQUEST,
  EDIT_QUESTIONNAIRES_INTERWEES_REQUEST,
  ANALYST_ANSWERING_QUESTION_REQUEST,
  GET_CUSTOMER_QUESTIONNAIRE_ANSWER_DETAIL_REQUEST,
  DELETE_CUSTOMER_PROJECT_QUESTIONNAIRE_REQUEST,
  CHECKLIST_ACTIVITY_ANSWER_REQUEST,
  CHECKLIST_ACTIVITY_EVIDENCES_REQUEST,
  EDIT_ACTIVITY_REGISTRATIONS_REQUEST,
  CHECKLIST_QUESTION_ANSWER_REQUEST,
  GET_ACTIVITY_DETAILS_REQUEST,
  GET_QUESTION_DETAILS_REQUEST,
  GET_CUSTOMER_PROJECT_CHECKLISTS_REQUEST,
  EDIT_ACTIVITY_ANSWER_REQUEST,
  EDIT_ACTIVITY_ANSWER_EVIDENCES_REQUEST,
  EDIT_QUESTION_ANSWER_EVIDENCES_REQUEST,
  GET_CUSTOMER_PROJECT_THREATS_REQUEST,
  GET_CUSTOMER_PROJECT_SCORE_CARD_REQUEST,
  DELETE_CUSTOMER_PROJECT_CROWN_JEWEL_REQUEST,
  EDIT_CUSTOMER_PROJECT_CSF_CORE_REQUEST,
  EDIT_CUSTOMER_PROJECT_CROWN_JEWEL_REQUEST,
  GET_THREATS_CUSTOMER_PROJECT_DETAIL_REQUEST,
  GET_ALL_REPORTS_TEXTS_CUSTOMER_PROJECT_REQUEST,
  EDIT_QUESTIONNAIRE_ANSWERS_REQUEST,
  SEND_ORGANOGRAM_REQUEST,
  GET_ORGANOGRAM_CUSTOMER_PROJECT_REQUEST,
  SEND_QUESTIONNAIRE_EVIDENCES_REQUEST,
  REMOVE_QUESTIONNAIRE_EVIDENCES_REQUEST,
  GET_ALL_REPORTS_PROJECT_CUSTOMER_PROJECT_REQUEST,
  EDIT_APROVE_PDF_REQUEST,
  SEND_INTERVIEW_EMAIL_REQUEST,
  EDIT_ACTIVITY_REGISTRATIONS_REQUEST_DETAIL,
  EDIT_ACTIVITY_REGISTRATIONS_REQUEST_DETAIL_TWO,
  EDIT_CUSTOMER_PROJECT_STATUS_REQUEST,
  GET_FILTERED_CUSTOMER_PROJECT_REQUEST,

  GET_CUSTOMER_PROJECT_QUESTIONNAIRES_REQUEST,
  NEW_GET_CUSTOMER_PROJECT_QUESTIONNAIRES_REQUEST,
  GET_CUSTOMER_PROJECT_QUESTIONNAIRES_DETAIL_REQUEST,
  PUT_PROJECT_STATUS_CHANGE_REQUEST,
  GET_SCORE_CARD_CHART_REQUEST,
  GET_RISK_DONUT_CHART_REQUEST,
  GET_RISK_HEAT_MAP_REQUEST,
  UPDATE_PROJECT_EXPIRATION_DATE_REQUEST,
  GET_PROJECT_CUSTOMER_DETAIL_REQUEST,
  GET_ALL_CUSTOMER_PROJECT_CUSTOMER_REQUEST,
  GET_ALL_ENV_ASSESSMENT_CUSTOMERPROJECT_REQUEST,
  PUT_IDENTIFIED_QUANTITY_REQUEST,
  PUT_AUDITED_INTERWEES_REQUEST,
} = CustomerProjectActions;

const {
  getCustomerProjectSuccess,
  getCustomerProjectFailure,
  createNewCustomerProjectRequestSuccess,
  createNewCustomerProjectRequestFailure,
  getAnalystsSuccess,
  getAnalystsFailure,
  getControlCustomersSuccess,
  getControlCustomersFailure,
  getControlProjectTypeSuccess,
  getControlProjectTypeFailure,
  editCustomerProjectSuccess,
  editCustomerProjectFailure,
  getCustomerProjectDetailSuccess,
  getCustomerProjectDetailFailure,
  editQuestionnaireInterweesVinculationsSuccess,
  editQuestionnaireInterweesVinculationsFailure,
  createAnalystQuestionnaireAnswerSuccess,
  createAnalystQuestionnaireAnswerFailure,
  getCustomerQuestionnaireAnswerDetailSuccess,
  getCustomerQuestionnaireAnswerDetailFailure,
  createActivityAnswerSuccess,
  createActivityAnswerFailure,
  sendActivityEvidencesSuccess,
  sendActivityEvidencesFailure,
  sendActivityEvidencesRequest,
  editActivityRegistrationsSuccess,
  editActivityRegistrationsFailure,
  createQuestionAnswerSuccess,
  createQuestionAnswerFailure,
  getActivityDetailsRequest,
  getActivityDetailsSuccess,
  getActivityDetailsFailure,
  getQuestionDetailsSuccess,
  getQuestionDetailsFailure,
  getCustomerProjectChecklistsSuccess,
  getCustomerProjectChecklistsFailure,
  getCustomerProjectChecklistsRequest,
  editActivityAnswerSuccess,
  editActivityAnswerFailure,
  editActivityAnswerEvidencesSuccess,
  editActivityAnswerEvidencesFailure,
  editQuestionAnswerEvidencesSuccess,
  editQuestionAnswerEvidencesFailure,
  getCustomerProjectThreatsSuccess,
  getCustomerProjectThreatsFailure,
  getCustomerProjectScoreCardRequest,
  getCustomerProjectScoreCardSuccess,
  getCustomerProjectScoreCardFailure,
  deleteCustomerProjectCrownJewelsSuccess,
  deleteCustomerProjectCrownJewelsFailure,
  editCustomerProjectCSFCoreSuccess,
  editCustomerProjectCSFCoreFailure,
  editCustomerProjectCrownJewelSuccess,
  editCustomerProjectCrownJewelFailure,
  getCustomerProjectThreatsDetailSuccess,
  getCustomerProjectThreatsDetailFailure,
  getCustomerProjectTextsSuccess,
  getCustomerProjectTextsFailure,
  editQuestionnaireAnswersSuccess,
  editQuestionnaireAnswersFailure,
  getCustomerProjectOrganogramsRequest,
  getCustomerProjectOrganogramsSuccess,
  getCustomerProjectOrganogramsFailure,
  sendQuestionnaireEvidencesRequest,
  sendQuestionnaireEvidencesSuccess,
  sendQuestionnaireEvidencesFailure,
  removeQuestionnaireEvidencesRequest,
  removeQuestionnaireEvidencesSuccess,
  removeQuestionnaireEvidencesFailure,
  getCustomerProjectReportsProjectRequest,
  getCustomerProjectReportsProjectSuccess,
  getCustomerProjectReportsProjectFailure,
  editReportApprovePdfSuccess,
  editReportApprovePdfFailure,
  sendInterviewEmailSuccess,
  sendInterviewEmailFailure,
  editCustomerProjectStatusSuccess,
  editCustomerProjectStatusFailure,
  getCustomerProjectRequest,
  getSearchFilteredCustomerProjectSuccess,
  getSearchFilteredCustomerProjectFailure,

  getCustomerProjectQuestionnairesRequest,
  getCustomerProjectQuestionnairesSuccess,
  getCustomerProjectQuestionnairesFailure,
  getNewCustomerProjectQuestionnairesRequest,
  getNewCustomerProjectQuestionnairesSuccess,
  getNewCustomerProjectQuestionnairesFailure,
  getCustomerProjectQuestionnairesDetailSuccess,
  getCustomerProjectQuestionnairesDetailFailure,
  getQuestionDetailsRequest,
  putProjectStatusChangeSuccess,
  putProjectStatusChangeFailure,
  getCustomerProjectDetailRequest,
  getCustomersProjectScoreCardRadarChatRequest,
  getCustomersProjectScoreCardRadarChatSuccess,
  getCustomersProjectScoreCardRadarChatFailure,
  getRiskDonutChartSuccess,
  getRiskDonutChartFailure,
  getRiskHeatMapSuccess,
  getRiskHeatMapFailure,
  updateProjectExpirationDateSuccess,
  updateProjectExpirationDateFailure,
  getProjectCustomerDetailSuccess,
  getProjectCustomerDetailFailure,
  getCustomerProjectCustomerSuccess,
  getCustomerProjecCustomertFailure,
  getEnvAssessmentCustomerProjectRequest,
  getEnvAssessmentCustomerProjectSuccess,
  getEnvAssessmentCustomerProjectFailure,
  putIdentifiedQuantitySuccess,
  putIdentifiedQuantityFailure,
  putLinkAuditedIntervieweesSuccess,
  putLinkAuditedIntervieweesFailure,
} = customerProjectActionsFunctions;

const {
  getCustomersProjectNISTQuestionnaireRequest,
} = customerProjectNistActionsFunctions;

const {
  getLastApiFetchDataSuccess,
  getLastApiFetchDataFailure
} = lastApiFetchDataActionsFunctions;

const {
  DEFAULT_ERROR_MESSAGE,
  SEARCH_FILTERED_CUSTOMER_PROJECT_ERROR_MESSAGE,
  SEARCH_FILTERED_CUSTOMER_PROJECT_SUCCESS_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE
} = ToastMessages;


function* fetchAllCustomersProjects(): Generator {
  try {
    const customerProjectResponse: AxiosResponse<CustomerProjectResults> | unknown = yield call(
      api,
      'GET',
      '/controls/customers-projects/?anonymized=False',
      {}
    );

    const {
      data: customersProjects,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<CustomerProjectResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCustomerProjectSuccess(customersProjects.results));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerProjectFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
  }

function* fetchCustomersProjectsChecklistsRequest(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjectQuestionnairePayload;
  try {
    const customerProjectResponse: AxiosResponse<CustomerProjectsChecklists[]> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${customerProjectId}/checklists-activities/`,
      {}
    );

    const {
      data: customersProjectsChecklist,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<CustomerProjectsChecklists[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCustomerProjectChecklistsSuccess(customersProjectsChecklist));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerProjectChecklistsFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
      DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchAllControlProjectTypeRequest(action: Action): Generator {
  const { isCustomerProjectPage } = action.payload as CustomerProjectPagePayload;
  try {
    const projectsResponse: AxiosResponse<ControlsProjectTypeResults> | unknown = yield call(
      api,
      'GET',
      '/controls/projects/',
      {}
    );

    const {
      data: allProjects,
      config: { url },
      status,
      statusText,
    } = projectsResponse as AxiosResponse<ControlsProjectTypeResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getControlProjectTypeSuccess(allProjects.results));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getControlProjectTypeFailure());

    if (currentError?.response?.status === 403) {
      if(!isCustomerProjectPage)
        toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchCustomerProjectDetail(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjectsDetailActionPayload;

  try {
    const projectSettingsEditingResponse: AxiosResponse<CustomerProjectDetail> | unknown = yield call(
      api,
      'GET',
      `controls/customers-projects/${customerProjectId}/detail/`,
      {}
    );

    const {
      data: customersProjectDetail,
      config: { url },
      status,
      statusText,
    } = projectSettingsEditingResponse as AxiosResponse<CustomerProjectDetail>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCustomerProjectDetailSuccess(customersProjectDetail));

  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerProjectDetailFailure());
  }
}

function* fetchCustomerQuestionnaireAnswerProjectDetail(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjectsDetailActionPayload;

  try {
    const projectSettingsEditingResponse: AxiosResponse<CustomerProjectDetail> | unknown = yield call(
      api,
      'GET',
      `controls/answers-questionnaire-interviewee/${customerProjectId}/detail/`,
      {}
    );

    const {
      data,
      config: { url },
      status,
      statusText,
    } = projectSettingsEditingResponse as AxiosResponse<CustomerProjectDetail>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCustomerQuestionnaireAnswerDetailSuccess());

  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerQuestionnaireAnswerDetailFailure());
  }
}

function* fetchAllControlCustomersRequest(action: Action): Generator {
  const { isCustomerProjectPage } = action.payload as CustomerProjectPagePayload;

  try {
    const customerResponse: AxiosResponse<ControlsCustomerResults> | unknown = yield call(
      api,
      'GET',
      '/controls/customers/',
      {}
    );

    const {
      data: allCustomers,
      config: { url },
      status,
      statusText,
    } = customerResponse as AxiosResponse<ControlsCustomerResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getControlCustomersSuccess(allCustomers.results));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getControlCustomersFailure());

    if (currentError?.response?.status === 403) {
      if(!isCustomerProjectPage)
        toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAllAnalystsRequest(action: Action): Generator {
  const { isCustomerProjectPage } = action.payload as CustomerProjectPagePayload;
  try {
    const analystsResponse: AxiosResponse<ControlsAnalystsResults> | unknown = yield call(
      api,
      'GET',
      '/controls/analysts/',
      {}
    );

    const {
      data: allAnalysts,
      config: { url },
      status,
      statusText,
    } = analystsResponse as AxiosResponse<ControlsAnalystsResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getAnalystsSuccess(allAnalysts.results));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getAnalystsFailure());

    if (currentError?.response?.status === 403) {
      if(!isCustomerProjectPage)
        toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchCreateNewCustomersProjects(action: Action): Generator {
  const { newCustomerProject } = action.payload as CustomerProjectsActionToSaga;  
  try {
    const customerProjectResponse: AxiosResponse<NewProjectCreate> | unknown = yield call(
      api,
      'POST',
      '/controls/customers-projects/create/',
      { ...newCustomerProject }
    );

    const {
      data: customerProjectData,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<NewProjectCreate>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(createNewCustomerProjectRequestSuccess());
    history.push(`/customer-project-registrations/${customerProjectData.id}/${customerProjectData.project_type.id}`);
    toast.success('Projeto cadastrado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(createNewCustomerProjectRequestFailure());

    if(currentError.response?.data.message.length && currentError.response?.data.message === '{\'name\': [ErrorDetail(string=\'customer project with this name already exists.\', code=\'unique\')]}') {
        yield put(getCustomerProjectRequest());
        toast.error('Falha ao cadastrar um projeto. Projeto com esse nome já existe');
    } else {
      toast.error('Falha ao cadastrar o projeto');
    }    
  }
}

function* fetchCreateAnalystAnsweringQuestionnaire(action: Action): Generator {
  const {
    answers,
    questions_create,
    projectId,
    questionnaireId,
    projectName,
  } = action.payload as AnalystAnsweringQuestion;

  try {
    const customerProjectResponse: AxiosResponse<AnalystAnswerCreateResponse> | unknown = yield call(
      api,
      'POST',
      '/controls/analyst-questionnaire-answers/create/',
      { answers, questions_create }
    );

    const {
      data,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<AnalystAnswerCreateResponse>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(createAnalystQuestionnaireAnswerSuccess());

    const hasAnswerEvidences = answers.find(e => e.evidences);
    const hasQuestionsCreateEvidences = questions_create.find(e => e.evidences);

    const thereIsEvidenceInAnswer = ThereIsEvidenceInAnswer(hasAnswerEvidences);
    const thereIsEvidenceInQuestionCreate = ThereIsEvidenceInQuestionCreate(hasQuestionsCreateEvidences);
    const thereIsEvidence = thereIsEvidenceInAnswer || thereIsEvidenceInQuestionCreate;

    if (thereIsEvidence) {
      const newAnswersEvidences: { [x: string]: any[]; }[] = [];

      questions_create.forEach(element => {
        if (element.evidences.length > 0) {
          const answerFound = data.answers.find(e => e.question_customer_project.name === element.name);

          const answerId = String(answerFound?.id);
          const answerevidences = element?.evidences as any[];

          newAnswersEvidences.push({
            [answerId]: answerevidences
          });
        }
      });

      answers.forEach(element => {
        if (element.evidences.length > 0) {
          const answerFound = data.answers.find(e => e.question.id === element.question);

          const answerFoundCustomerProject = data.answers.find(e => e.question_customer_project.id === element.question_customer_project);

          const answerId = String(answerFound ? answerFound.id : answerFoundCustomerProject?.id);
          const answerevidences = element?.evidences as any[];

          newAnswersEvidences.push({
            [answerId]: answerevidences
          });
        }
      });

      const localtype: LocalType = {
        projectId,
        questionnaireId,
        projectName,
        whereDidItComeFrom: 'QUESTIONNAIRE_ANALYST_ANSWER',
        redirectToAnotherPage: thereIsEvidenceInQuestionCreate,
      };

      if (newAnswersEvidences.length >= 1)
        yield put(sendQuestionnaireEvidencesRequest(newAnswersEvidences, localtype));
    }

    if (questions_create.length > 0)
      toast.success('Questionário enviado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(createAnalystQuestionnaireAnswerFailure());

    toast.error('Falha ao enviar questionário.');
  }
}

function* fetchCreateChecklistActivityEvidencesRequest(action: Action): Generator {
  const { evidencesBody, answerId } = action.payload as EvidencePayloadApiBody;

  const formData = new FormData();

  evidencesBody.forEach(element => {
    formData.append('file', element as unknown as Blob, element.name);
  });

  try {
    const checklistActivityEvidencesResponse: AxiosResponse<ChecklistActivityAnswerPayloadType> | unknown = yield call(
      api,
      'POST',
      `/controls/evidences/${answerId}/create/`,
      formData,
      'multipart/form-data'
    );

    const {
      config: { url },
      status,
      statusText,
    } = checklistActivityEvidencesResponse as AxiosResponse<ChecklistActivityAnswerPayloadType>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(sendActivityEvidencesSuccess());

    toast.success('Evidências enviadas com sucesso.');

  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(sendActivityEvidencesFailure());

    toast.error('Falha ao enviar evidências.');
  }
}

function* fetchCreateChecklistActivityAnswerRequest(action: Action): Generator {
  const { answerBody, evidencesBody, disabledChange } = action.payload as ChecklistActivityAnswerPayloadType;
  try {
    const checklistActivityAnswerResponse: AxiosResponse<ChecklistActivityAnswerResponseType> | unknown = yield call(
      api,
      'POST',
      '/controls/activity-answer/create/',
      { ...answerBody },
    );

    const {
      data,
      config: { url },
      status,
      statusText,
    } = checklistActivityAnswerResponse as AxiosResponse<ChecklistActivityAnswerResponseType>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    if (status === 200 || status === 201) {
      yield put(createActivityAnswerSuccess());

      if (evidencesBody.length > 0) {
        yield put(sendActivityEvidencesRequest(evidencesBody, data.id));
      }

      toast.success('Atividade respondida com sucesso.');
      history.push(`/checklist-answer-activity-registrations/${answerBody.customer_project}/${data.id}/${disabledChange}`);
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(createActivityAnswerFailure());

    toast.error('Falha ao enviar atividade.');
  }
}

function* fetchEditChecklistActivityAnswerAndEvidenceRequest(action: Action): Generator {
  const { activityAnswerBody, activityId, newEvidences, customerProjectId, disableChange } = action.payload as ActivityEvidencesPayloadToApi;
  try {
    const checklistActivityAnswerResponse: AxiosResponse<ActivityEvidencesPayloadToApi> | unknown = yield call(
      api,
      'PUT',
      `/controls/answer-evidences/${activityId}/`,
      { ...activityAnswerBody },
    );

    const {
      config: { url },
      status,
      statusText,
    } = checklistActivityAnswerResponse as AxiosResponse<ActivityEvidencesPayloadToApi>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editActivityAnswerEvidencesSuccess());
    yield put(getActivityDetailsRequest(activityId));

    toast.success('Atividade editada com sucesso.');
    history.push(`/checklist-answer-activity-registrations/${customerProjectId}/${activityId}/${disableChange}`);

    if (newEvidences.length > 0) {
      yield put(sendActivityEvidencesRequest(newEvidences, activityId));
    }

  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editActivityAnswerEvidencesFailure());

    toast.error('Falha ao editar atividade.');
  }
}

function* fetchEditChecklistActivityAnswerRequest(action: Action): Generator {
  const { answerBody, evidencesBody, activityId } = action.payload as EditChecklistActivityAnswerPayloadType;
  try {
    const checklistActivityAnswerResponse: AxiosResponse<EditChecklistActivityAnswerResponse> | unknown = yield call(
      api,
      'PUT',
      `/controls/activity-answer/${activityId}/`,
      { answerBody },
    );

    const {
      config: { url },
      status,
      statusText,
    } = checklistActivityAnswerResponse as AxiosResponse<EditChecklistActivityAnswerResponse>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editActivityAnswerSuccess());

    yield put(sendActivityEvidencesRequest(evidencesBody, activityId));

    toast.success('Atividade respondida com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editActivityAnswerFailure());

    toast.error('Falha ao enviar atividade.');
  }
}

function* fetchEditActivityRegistrationsRequest(action: Action): Generator {
  const { activityRegistrations, activityId, redirectId, disableChange } = action.payload as ActivityRegistrationsPayloadApi;
  try {
    const customerProjectResponse: AxiosResponse<QuestionnaireInterviwees> | unknown = yield call(
      api,
      'PUT',
      `/controls/answers/${activityId}/`,
      { ...activityRegistrations }
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<QuestionnaireInterviwees>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editActivityRegistrationsSuccess());
    history.push(`/customer-project-checklist-question-detail/${redirectId}/${activityId}/${disableChange}`);
    toast.success('Vínculos registrados com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editActivityRegistrationsFailure());

    toast.error('Falha ao registrar o(s) vínculos(s).');
  }
}

function* fetchChecklistActivityDetails(action: Action): Generator {
  const { activityId } = action.payload as ChecklistActivityDetailActionPayload;

  const activityAnswerId = activityId;

  try {
    const projectSettingsEditingResponse: AxiosResponse<ChecklistActivityDetail> | unknown = yield call(
      api,
      'GET',
      `controls/activity-answer/${activityAnswerId}/detail/`,
      {}
    );

    const {
      data: checklistActivityResponseData,
      config: { url },
      status,
      statusText,
    } = projectSettingsEditingResponse as AxiosResponse<ChecklistActivityDetail>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getActivityDetailsSuccess(checklistActivityResponseData));

  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getActivityDetailsFailure());
  }
}

function* fetchChecklistQuestionDetails(action: Action): Generator {
  const { questionId } = action.payload as ChecklistQuestionDetailActionPayload;

  try {
    const projectSettingsEditingResponse: AxiosResponse<ChecklistActivityDetail> | unknown = yield call(
      api,
      'GET',
      `controls/answer-question-checklist/${questionId}/detail/`,
      {}
    );

    const {
      data: checklistQuestionResponseData,
      config: { url },
      status,
      statusText,
    } = projectSettingsEditingResponse as AxiosResponse<ChecklistActivityDetail>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getQuestionDetailsSuccess(checklistQuestionResponseData));

  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getQuestionDetailsFailure());
  }
}

function* editChecklistQuestionEvidencesRequest(action: Action): Generator {
  const { questionId, questionAnswerBody, newEvidences, customerProjectId } = action.payload as QuestionEvidencesPayloadToApi;

  try {
    const editChecklistQuestionEvidenceResponse: AxiosResponse<QuestionEvidencesPayloadToApi> | unknown = yield call(
      api,
      'PUT',
      `controls/answer-question-checklist/${questionId}/`,
      { ...questionAnswerBody }
    );

    const {
      config: { url },
      status,
      statusText,
    } = editChecklistQuestionEvidenceResponse as AxiosResponse<QuestionEvidencesPayloadToApi>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    if (status === 200 || status === 201) {
      if (newEvidences.length > 0) {
        yield put(sendActivityEvidencesRequest(newEvidences, customerProjectId));
      }

      yield put(editQuestionAnswerEvidencesSuccess());
      yield put(getQuestionDetailsRequest(questionId));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editQuestionAnswerEvidencesFailure());
  }
}

function* fetchCreateChecklistQuestionAnswerRequest(action: Action): Generator {
  const { answerBody, evidencesBody } = action.payload as ChecklistQuestionAnswerPayloadType;
  try {
    const checklistActivityAnswerResponse: AxiosResponse<ChecklistQuestionAnswerResponseBody> | unknown = yield call(
      api,
      'POST',
      '/controls/answer-question-checklist/create/',
      { ...answerBody },
    );

    const {
      data,
      config: { url },
      status,
      statusText,
    } = checklistActivityAnswerResponse as AxiosResponse<ChecklistQuestionAnswerResponseBody>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(createQuestionAnswerSuccess());
    yield put(getCustomerProjectChecklistsRequest(answerBody.customer_project));

    yield put(sendActivityEvidencesRequest(evidencesBody, data.id));

    toast.success('Pergunta respondida com sucesso.');

  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(createQuestionAnswerFailure());

    toast.error('Falha ao respoder pergunta.');
  }
}

function* fetchEditQuestionnaireInterviwees(action: Action): Generator {
  const { questionnaireId, questionnaireInterwees, customerProjectId } = action.payload as QuestionnaireInterviweesPayload;
  try {
    const customerProjectResponse: AxiosResponse<QuestionnaireInterviwees> | unknown = yield call(
      api,
      'PUT',
      `/controls/questionnaire-interviewees/${questionnaireId}/`,
      { ...questionnaireInterwees }
    );

    const {
      data: questionnaireInterweesData,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<QuestionnaireInterviwees>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    if (status === 200 || status === 201) {
      yield put(editQuestionnaireInterweesVinculationsSuccess());
      yield put(getNewCustomerProjectQuestionnairesRequest(customerProjectId));
      toast.success('Entrevistado vinculado com sucesso.');
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editQuestionnaireInterweesVinculationsFailure());

    toast.error('Falha ao vincular o(s) entrevistado(s).');
  }
}

function* fetchDeleteCustomerProjectQuestionnaire(action: Action): Generator {
  const questionnaireId = action.payload as unknown as string;
  try {
    const customerProjectResponse: AxiosResponse<QuestionnaireInterviwees> | unknown = yield call(
      api,
      'DELETE',
      `/controls/questionnaire-interviewees/${questionnaireId}/delete/`,
      {}
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<QuestionnaireInterviwees>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editQuestionnaireInterweesVinculationsSuccess());
    toast.success('Questionário apagado com sucesso.');

  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editQuestionnaireInterweesVinculationsFailure());

    toast.error('Erro ao apagar questionário.');
  }
}

function* fetchCustomersProjectsThreatsRequest(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjectQuestionnairePayload;
  try {
    const customerProjectResponse: AxiosResponse<ProjectsThreats> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${customerProjectId}/threats/`,
      {}
    );

    const {
      data: projectsThreats,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<ProjectsThreats>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCustomerProjectThreatsSuccess(projectsThreats));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerProjectThreatsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchCustomersProjectsScoreCardRequest(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjectQuestionnairePayload;
  try {
    const customerProjectScoreCardResponse: AxiosResponse<CustomerProjectScoreCard> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${customerProjectId}/score-card-detail/`,
      {}
    );

    const {
      data: customersProjectsScoreCard,
      config: { url },
      status,
      statusText,
    } = customerProjectScoreCardResponse as AxiosResponse<CustomerProjectScoreCard>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    if (status === 200 || status === 201) {
      yield put(getCustomersProjectScoreCardRadarChatRequest(customerProjectId));
      yield put(getCustomerProjectScoreCardSuccess(customersProjectsScoreCard));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerProjectScoreCardFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchDeleteCustomerProjectCrownJewelRequest(action: Action): Generator {
  const { crownJewelId, customerProjectId } = action.payload as CustomerProjectDeleteCrownJewelPayload;
  try {
    const customerProjectScoreCardResponse: AxiosResponse<CustomerProjectScoreCard> | unknown = yield call(
      api,
      'DELETE',
      `/controls/crown-jewels/${crownJewelId}/delete/`,
      {}
    );

    const {
      data,
      config: { url },
      status,
      statusText,
    } = customerProjectScoreCardResponse as AxiosResponse<CustomerProjectScoreCard>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(deleteCustomerProjectCrownJewelsSuccess());
    yield put(getCustomerProjectScoreCardRequest(customerProjectId));
    toast.success('Jóia da coroa deletada com sucesso');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(deleteCustomerProjectCrownJewelsFailure());

    toast.success('Erro ao deletar jóia da coroa');
  }
}

function* fetchEditCustomerProjectCSFcoreRequest(action: Action): Generator {
  const { CSFCoreId, maturityLevel, customerProjectId, version } = action.payload as CustomerProjectEditCSFCorePayload;
  try {
    const customerProjectScoreCardResponse: AxiosResponse<CustomerProjectScoreCard> | unknown = yield call(
      api,
      'PUT',
      `/controls/csf-core-project/${CSFCoreId}/`,
      {
        maturity_level: maturityLevel
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerProjectScoreCardResponse as AxiosResponse<CustomerProjectScoreCard>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editCustomerProjectCSFCoreSuccess());

    if (version === '1') {
      yield put(getCustomerProjectScoreCardRequest(customerProjectId));
      toast.success('Maturidade editada com sucesso.');
    }
    else
      yield put(getCustomersProjectNISTQuestionnaireRequest(customerProjectId));

  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editCustomerProjectCSFCoreFailure());

    toast.error('Erro ao editar maturidade.');
  }
}

function* fetchEditCustomerProjectCrownJewelRequest(action: Action): Generator {
  const { crownJewelsId, result, weight, customerProjectId } = action.payload as CustomerProjectEditCrownJewelPayload;

  try {
    const customerProjectScoreCardResponse: AxiosResponse<CustomerProjectScoreCard> | unknown = yield call(
      api,
      'PUT',
      `/controls/crown-jewels/${crownJewelsId}/`,
      {
        weight,
        result
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerProjectScoreCardResponse as AxiosResponse<CustomerProjectScoreCard>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editCustomerProjectCrownJewelSuccess());
    yield put(getCustomerProjectScoreCardRequest(customerProjectId));
    toast.success('Atingimento e peso editado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editCustomerProjectCrownJewelFailure());

    toast.error('Erro ao editar atingimento.');
  }
}

function* fetchThreatsCustomersDetailProject(action: Action): Generator {
  const { threatId } = action.payload as CustomerProjetcDetailApiPayload;

  try {
    const threatCustomerProjectResponse: AxiosResponse<CustomerProjectsThreats> | unknown = yield call(
      api,
      'GET',
      `/controls/threats-customer-project/${threatId}/detail/`,
      {}
    );

    const {
      data: customersProjectsThreat,
      config: { url },
      status,
      statusText,
    } = threatCustomerProjectResponse as AxiosResponse<CustomerProjectsThreats>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCustomerProjectThreatsDetailSuccess(customersProjectsThreat));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerProjectThreatsDetailFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAllReportsTextsCustomerProject(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjetcIdApiPayload;
  try {
    const customerProjectResponse: AxiosResponse<ReportsText> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${customerProjectId}/text-pdf-detail/`,
      {}
    );

    const {
      data: customersProjectsReports,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<ReportsText>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCustomerProjectTextsSuccess(customersProjectsReports));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerProjectTextsFailure());
  }
}

function* editQuestionnaireAnswerRequestSagas(action: Action): Generator {
  const {
    answers,
    questions_create,
    customerProjectId,
    evidencesToRemove,
    questionnaireId,
    projectName,
    disabledChange,
    handleGetAnswer,
  } = action.payload as EditQuestionnaireAnswerBody;

  const evidenceRemove = {
    answers: evidencesToRemove
  };

  try {
    const customerProjectResponse: AxiosResponse<AnalystAnswerCreateResponse> | unknown = yield call(
      api,
      'PUT',
      '/controls/create-and-edit-answers-questionnaire/',
      { answers, questions_create }
    );

    const {
      data,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<AnalystAnswerCreateResponse>;

    handleGetAnswer(data.answers[0]);

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    if (status === 200) {
      yield put(editQuestionnaireAnswersSuccess());

      const hasAnswerEvidences = answers.find(e => e.evidences);
      const hasQuestionsCreateEvidences = questions_create.find(e => e.evidences);

      const thereIsEvidenceInAnswer = ThereIsEvidenceInAnswer(hasAnswerEvidences);
      const thereIsEvidenceInQuestionCreate = ThereIsEvidenceInQuestionCreate(hasQuestionsCreateEvidences);
      const thereIsEvidenceToRemove = ThereIsEvidenceToRemove(evidenceRemove.answers);

      let goAddEvidence = false;

      if (thereIsEvidenceInAnswer || thereIsEvidenceInQuestionCreate || thereIsEvidenceToRemove) {
        const localtype: LocalType = {
          projectId: customerProjectId,
          questionnaireId,
          projectName,
          disabledChange,
          whereDidItComeFrom: 'QUESTIONNAIRE_EDIT_ANSWER',
          redirectToAnotherPage: thereIsEvidenceInQuestionCreate,
        };

        if (hasAnswerEvidences || hasQuestionsCreateEvidences) {
          const newAnswersEvidences: { [x: string]: any[]; }[] = [];

          questions_create.forEach(element => {
            if (element.evidences.length > 0) {
              const answerFound = data.answers.find(e => e.question_customer_project.name === element.name);

              const answerId = String(answerFound?.id);
              const answerevidences = element?.evidences as any[];

              newAnswersEvidences.push({
                [answerId]: answerevidences
              });
            }
          });

          answers.forEach(element => {
            if (element.evidences.length > 0) {
              const answerFound = data.answers.find(e => e.question.id === element.question);

              const answerFoundCustomerProject = data.answers.find(e => e.question_customer_project.id === element.question_customer_project);

              const answerId = String(answerFound ? answerFound.id : answerFoundCustomerProject?.id);
              const answerevidences = element?.evidences as any[];

              newAnswersEvidences.push({
                [answerId]: answerevidences
              });
            }
          });

          if (newAnswersEvidences.length >= 1) {
            goAddEvidence = true;
            yield put(sendQuestionnaireEvidencesRequest(newAnswersEvidences, localtype));
          }
        }

        if (thereIsEvidenceToRemove) {
          yield put(removeQuestionnaireEvidencesRequest(evidenceRemove, localtype));
          evidenceRemove.answers = [];
        }
      }

      if (questions_create.length > 0 && status === 200 && !goAddEvidence) {
        toast.success('Questionário editado com sucesso.');
        history.push(`/project-questionnaire-visualization/${customerProjectId}/${questionnaireId}/${projectName}/${disabledChange}`);
      }
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editQuestionnaireAnswersFailure());
    toast.error('Falha ao editar questionário.');
  }
}

function* fetchCreateOrganogramRequest(action: Action): Generator {
  const { organogramBody, customerProjectId } = action.payload as OrganogramPayloadApiBody;

  const formData = new FormData();

  organogramBody.forEach(element => {
    formData.append('file', element as unknown as Blob, element.name);
  });

  try {
    const checklistActivityEvidencesResponse: AxiosResponse<ChecklistActivityAnswerPayloadType> | unknown = yield call(
      api,
      'PUT',
      `/controls/customers-projects/${customerProjectId}/update-organogram/`,
      formData,
      'multipart/form-data'
    );

    const {
      config: { url },
      status,
      statusText,
    } = checklistActivityEvidencesResponse as AxiosResponse<ChecklistActivityAnswerPayloadType>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(sendActivityEvidencesSuccess());
    yield put(getCustomerProjectOrganogramsRequest(customerProjectId));

    toast.success('Evidências enviadas com sucesso.');

  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(sendActivityEvidencesFailure());

    toast.error('Falha ao enviar evidências.');
  }
}

function* fetchOrganogramCustomersProjectsRequest(action: Action): Generator {
  const { customerProjectId } = action.payload as OrganogramPayloadApiBody;
  try {
    const customerProjectResponse: AxiosResponse<OrganogramType> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${customerProjectId}/organogram/`,
      {}
    );

    const {
      data: organogram,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<OrganogramType>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCustomerProjectOrganogramsSuccess(organogram));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerProjectOrganogramsFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
      DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchQuestionnaireAnswersEvidencesRequest(action: Action): Generator {
  const {
    evidencesIdsBody,
    pLocal
  } = action.payload as AnswersQuestionnaireEvidences;

  let count = 0;

  const formData = new FormData();

  evidencesIdsBody.forEach(files => {
    const idKey = Object.keys(files)[0];

    formData.append('ids', idKey);

    files[idKey].forEach((file: any) => {
      if (file.name !== undefined) {
        formData.append(idKey, file as unknown as Blob, file.name);
        count += 1;
      }
    });
  });

  try {
    if (count >= 1) {
      const customerProjectResponse: AxiosResponse<any> | unknown = yield call(
        api,
        'POST',
        '/controls/evidences-answers-questionnaire/create/',
        formData,
        'multipart/form-data'
      );

      const {
        data,
        config: { url },
        status,
        statusText,
      } = customerProjectResponse as AxiosResponse<any>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      yield put(sendQuestionnaireEvidencesSuccess());
      if (pLocal !== null) {
        if (status === 201 && pLocal?.redirectToAnotherPage === true) {
          toast.success('Questionário editado com sucesso.');
          history.push(`/project-questionnaire-visualization/${pLocal.projectId}/${pLocal.questionnaireId}/${pLocal.projectName}/${pLocal.disabledChange}`);
        }
      }
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(sendQuestionnaireEvidencesFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
      DEFAULT_ERROR_MESSAGE
    );
  }
}

function* removeCustomersProjectsQuestionnaireEvidencesRequest(action: Action): Generator {
  const { answers } = action.payload as AnswersEvidencesToRemove;

  try {
    const customerProjectResponse: AxiosResponse<any> | unknown = yield call(
      api,
      'PUT',
      '/controls/answers-remove-evidences/',
      { ...answers }
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<any>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(removeQuestionnaireEvidencesSuccess());
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(removeQuestionnaireEvidencesFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchReportsProjectCustomerProject(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjetcIdApiPayload;
  try {
    const customerProjectResponse: AxiosResponse<ReportsBody> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${customerProjectId}/reports/`,
      {},
    );

    const {
      data: customersProjectsReports,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<ReportsBody>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCustomerProjectReportsProjectSuccess(customersProjectsReports));

  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerProjectReportsProjectFailure());
  }
}

function* fetchReportsApprovePdfProject(action: Action): Generator {
  const { customerProjectId, approveStatus } = action.payload as CustomerProjetcPDFApproveIdApiPayload;

  const pdfStatus = {
    pdf_report_is_approved: !approveStatus
  };

  try {
    const customerProjectResponse: AxiosResponse<any> | unknown = yield call(
      api,
      'PUT',
      `/controls/customers-projects/${customerProjectId}/update-pdf-report-is-approved/`,
      { ...pdfStatus },
    );

    const {
      data,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<any>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editReportApprovePdfSuccess());
    yield put(getCustomerProjectReportsProjectRequest(customerProjectId));


  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editReportApprovePdfFailure());

    toast.error('Erro ao aprovar PDF.');
  }
}

function* sendInterviewInviteEmailRequest(action: Action): Generator {
  const { questionnaireCustomerProjectId } = action.payload as sendInterviewEmailApiPayload;

  try {
    const customerProjectResponse: AxiosResponse<any> | unknown = yield call(
      api,
      'POST',
      `/controls/send-email-interviewees/${questionnaireCustomerProjectId}/`,
      {},
    );

    const {
      data,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<any>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editReportApprovePdfSuccess());
    yield put(sendInterviewEmailSuccess());

    toast.success('Convite enviado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(sendInterviewEmailFailure());

    toast.error('Erro ao enviar o convite.');
  }
}

function* fetchEditActivityRegistrationsRequestDetail(action: Action): Generator {
  const {
    activityRegistrations,
    activityId,
    redirectId,
    questionnaireId,
    projectName,
    disableChange
  } = action.payload as ActivityRegistrationsPayloadApiDetail;

  try {
    const customerProjectResponse: AxiosResponse<QuestionnaireInterviwees> | unknown = yield call(
      api,
      'PUT',
      `/controls/answers/${activityId}/`,
      { ...activityRegistrations }
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<QuestionnaireInterviwees>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editActivityRegistrationsSuccess());
    history.push(`/project-questionnaire-visualization/${redirectId}/${questionnaireId}/${projectName}/${disableChange}`);
    toast.success('Vínculos registrados com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editActivityRegistrationsFailure());

    toast.error('Falha ao registrar o(s) vínculos(s).');
  }
}

function* fetchEditActivityRegistrationsRequestDetailTwo(action: Action): Generator {
  const { activityRegistrations, activityId, disableChange } = action.payload as ActivityRegistrationsPayloadApi;
  try {
    const customerProjectResponse: AxiosResponse<QuestionnaireInterviwees> | unknown = yield call(
      api,
      'PUT',
      `/controls/answers/${activityId}/`,
      { ...activityRegistrations }
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<QuestionnaireInterviwees>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editActivityRegistrationsSuccess());

    history.push(`/customer-project-checklist-activity-detail/${activityId}/${disableChange}`);
    toast.success('Vínculos registrados com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editActivityRegistrationsFailure());

    toast.error('Falha ao registrar o(s) vínculos(s).');
  }
}

function* editCustomerProjectStatusRequest(action: Action): Generator {
  const {
    customerProjectId,
    changeStatusBody
  } = action.payload as CustomerProjectChangeStatusApiPayload;

  try {
    const customerProjectResponse: AxiosResponse<any> | unknown = yield call(
      api,
      'PUT',
      `/controls/customers-projects/${customerProjectId}/update-status/`,
      { ...changeStatusBody }
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<any>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editCustomerProjectStatusSuccess());

    toast.success('Status alterado com sucesso.');

    yield put(getCustomerProjectRequest());
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editCustomerProjectStatusFailure());

    toast.success('Erro ao alterar o status.');
  }
}

function* fetchSearchFilteredCustomerProjects(action: Action): Generator {
  const {
    filteredCustomerProjects,
    isActive,
    status_project,
    sector,
    subSector,
    segment,
    anonymized,
    showMessenger
  } = action.payload as SearchFilteredCustomerProjectRequestPayloadParams;

  const parsedResponses = filteredCustomerProjects.filter(response => response !== null).join(',');

  const ReturnAPIRequest = (
    (pSearch: string, pActive: boolean | null, pStatusProject: string | null, pSector: string, pSubSector: string, pSegment: string, pAnonymized: boolean | null) => {
      let result = '/controls/customers-projects/?';
      if (pSearch !== '') {
        result = `${result}search=${pSearch}`;
        if (pSector !== '')
          result = result.concat(`&&sector__name=${pSector}`);
        if (pSubSector !== '')
          result = result.concat(`&&subsector__name=${pSubSector}`);
        if (pSegment !== '')
          result = result.concat(`&&segment__name=${pSegment}`);
        if (pStatusProject !== null)
          result = result.concat(`&&project_status=${pStatusProject}`);
        if (pActive !== null)
          result = result.concat(`&&is_active=${pActive}`);
        if (pAnonymized !==  null)
          result = result.concat(`&&anonymized=${pAnonymized}`);
      } else if (pSearch === '' && pSector !== '') {
        result = `${result}sector__name=${pSector}`;
        if (pSubSector !== '')
          result = result.concat(`&&subsector__name=${pSubSector}`);
        if (pSegment !== '')
          result = result.concat(`&&segment__name=${pSegment}`);
        if (pStatusProject !== null)
          result = result.concat(`&&project_status=${pStatusProject}`);
        if (pActive !== null)
          result = result.concat(`&&is_active=${pActive}`);
        if (pAnonymized !==  null)
          result = result.concat(`&&anonymized=${pAnonymized}`);
      } else if (pSearch === '' && pSector === '' && pSubSector !== '') {
        result = `${result}subsector__name=${pSubSector}`;
        if (pSegment !== '')
          result = result.concat(`&&segment__name=${pSegment}`);
        if (pStatusProject !== null)
          result = result.concat(`&&project_status=${pStatusProject}`);
        if (pActive !== null)
          result = result.concat(`&&is_active=${pActive}`);
        if (pAnonymized !==  null)
          result = result.concat(`&&anonymized=${pAnonymized}`);
      } else if (pSearch === '' && pSector === '' && pSubSector === '' && pSegment !== '') {
        result = `${result}segment__name=${pSegment}`;
        if (pStatusProject !== null)
          result = result.concat(`&&project_status=${pStatusProject}`);
        if (pActive !== null)
          result = result.concat(`&&is_active=${pActive}`);
        if (pAnonymized !==  null)
          result = result.concat(`&&anonymized=${pAnonymized}`);
      } else if (pSearch === '' && pSector === '' && pSubSector === '' && pSegment === '' && pStatusProject !== null) {
        result = `${result}project_status=${pStatusProject}`;
        if (pActive !== null)
          result = result.concat(`&&is_active=${pActive}`);
        if (pAnonymized !==  null)
          result = result.concat(`&&anonymized=${pAnonymized}`);
      } else if (pSearch === '' && pSector === '' && pSubSector === '' && pSegment === '' && pStatusProject === null && pActive !== null) {
        result = `${result}is_active=${pActive}`;
        if (pAnonymized !==  null)
          result = result.concat(`&&anonymized=${pAnonymized}`);
      } else if (pSearch === '' && pSector === '' && pSubSector === '' && pSegment === '' && pStatusProject === null && pActive === null && pAnonymized !== null) {
        result = `${result}anonymized=${pAnonymized}`;
      }

      return result;
    }
  );

  try {
    const filteredCustomerProjectsResponse: AxiosResponse<CustomerProjectResults> | unknown = yield call(
      api,
      'GET',
      ReturnAPIRequest(parsedResponses, isActive, status_project, sector, subSector, segment, anonymized),
      {}
    );

    const {
      data: filtered,
      config: { url },
      status,
      statusText,
    } = filteredCustomerProjectsResponse as AxiosResponse<CustomerProjectResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredCustomerProjectSuccess(filtered.results));
    if (showMessenger)
      toast.success(SEARCH_FILTERED_CUSTOMER_PROJECT_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredCustomerProjectFailure());

    toast.error(currentError?.response?.data?.detail ?? SEARCH_FILTERED_CUSTOMER_PROJECT_ERROR_MESSAGE);
  }
}

function* fetchCustomersProjectsQuestionnairesRequest(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjectQuestionnairePayload;
  try {
    const customerProjectResponse: AxiosResponse<CustomerProjectsQuestionnaires[]> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${customerProjectId}/questionnaires/`,
      {}
    );

    const {
      data: customersProjectsQuestionnaire,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<CustomerProjectsQuestionnaires[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCustomerProjectQuestionnairesSuccess(customersProjectsQuestionnaire));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerProjectQuestionnairesFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
      DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchNewCustomersProjectsQuestionnairesRequest(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjectQuestionnairePayload;
  try {
    const customerProjectResponse: AxiosResponse<NewCustomerProjectsQuestionnaires[]> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${customerProjectId}/questionnaires-project/`,
      {}
    );

    const {
      data: newCustomerProjectQuestionnaires,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<NewCustomerProjectsQuestionnaires[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getNewCustomerProjectQuestionnairesSuccess(newCustomerProjectQuestionnaires));
  } catch (error) {
    const currentError = error as AxiosError;

    if (currentError.response?.status === 403) {
      console.warn('Acesso negado (403) ao buscar as avaliações ambientais.');
    } else {
      yield put(getLastApiFetchDataFailure(currentError));
      yield put(getNewCustomerProjectQuestionnairesFailure());
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }    
  }
}

function* fetchToCustomerProjectEditing(action: Action): Generator {
  const {
    editCustomerProject,
    customerProjectId,
    shouldRedirect,
    ProjectTypeId,
    isCheckListsOrActivity,
  } = action.payload as EditCustomerProjectRequestPayload;

  try {
    const projectSettingsEditingResponse: AxiosResponse<EditCustomerProjectRequestPayload> | unknown = yield call(
      api,
      'PUT',
      `controls/customers-projects/${customerProjectId}/`,
      {
        ...editCustomerProject
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = projectSettingsEditingResponse as AxiosResponse<EditCustomerProjectRequestPayload>;


    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editCustomerProjectSuccess());

    toast.success('Ação realizada com sucesso.');

    if (isCheckListsOrActivity) {
      yield put(getCustomerProjectChecklistsRequest(customerProjectId));
    } else if (!shouldRedirect) {
      yield put(getCustomerProjectFailure());
      yield put(getNewCustomerProjectQuestionnairesRequest(customerProjectId));
      if (sessionStorage.getItem('CustomerProjectId') !== null)
        sessionStorage.removeItem('CustomerProjectId');
  
      sessionStorage.setItem('CustomerProjectId', customerProjectId.toString());    
      history.push('/customer-project-detail?tab=0');
    } else {
      yield put(getNewCustomerProjectQuestionnairesRequest(customerProjectId));
      history.push(`/customer-project-registrations/${customerProjectId}/${ProjectTypeId}`);
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editCustomerProjectFailure());

    toast.error('Erro ao executar a ação.');
  }
}

function* fetchCustomersProjectsQuestionnairesDetailRequest(action: Action): Generator {
  const { customerProjectQuestionnaireId } = action.payload as CustomerProjectQuestionnaireDetailPayload;

  try {
    const customerProjectResponseDetail: AxiosResponse<CustomerProjectsQuestionnaireDetails[]> | unknown = yield call(
      api,
      'GET',
      `/controls/customer-project-questionnaire/${customerProjectQuestionnaireId}/detail/`,
      {}
    );

    const {
      data: customerProjectsQuestionnaireDetails,
      config: { url },
      status,
      statusText,
    } = customerProjectResponseDetail as AxiosResponse<CustomerProjectsQuestionnaireDetails[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCustomerProjectQuestionnairesDetailSuccess(customerProjectsQuestionnaireDetails));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerProjectQuestionnairesDetailFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchPutProjectStatusChangeRequest(action: Action): Generator {
  const { customerProjectId, project_status } = action.payload as ProjectStatuschangePayloadApi;
  try {
    const customerProjectResponse: AxiosResponse<CustomerProjectsQuestionnaires[]> | unknown = yield call(
      api,
      'PUT',
      `/controls/customers-projects/${customerProjectId}/update-project-status/`,
      { 'project_status': project_status }
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<CustomerProjectsQuestionnaires[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(putProjectStatusChangeSuccess());
    if (status === 200 || status === 201) {
      yield put(getCustomerProjectDetailRequest(customerProjectId));
      toast.success('O status de andamento do projeto foi atualizado com sucesso.');
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(putProjectStatusChangeFailure());

    toast.error('Erro ao tentar atualizar o status de andamento do projeto.');
  }
}

function* fetchCustomersProjectsScoreCardRadarChatRequest(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjectQuestionnairePayload;
  try {
    const scoreCardRadarChartResponse: AxiosResponse<ScoreCardRadarChart> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${customerProjectId}/csf-core-project-radar-chart/`,
      {}
    );

    const {
      data: scoreCardRadarChart,
      config: { url },
      status,
      statusText,
    } = scoreCardRadarChartResponse as AxiosResponse<ScoreCardRadarChart>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCustomersProjectScoreCardRadarChatSuccess(scoreCardRadarChart));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomersProjectScoreCardRadarChatFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchCustomersProjectsRiskDonutChartRequest(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjectQuestionnairePayload;
  try {
    const riskDonutChartResponse: AxiosResponse<RiskDonutChart> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${customerProjectId}/risk-donut-chart/`,
      {}
    );

    const {
      data: riskDonutChart,
      config: { url },
      status,
      statusText,
    } = riskDonutChartResponse as AxiosResponse<RiskDonutChart>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getRiskDonutChartSuccess(riskDonutChart));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getRiskDonutChartFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchCustomersProjectsRiskHeatMapRequest(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjectQuestionnairePayload;
  try {
    const riskHeatMapResponse: AxiosResponse<RiskHeatMap> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${customerProjectId}/risk-map/`,
      {}
    );

    const {
      data: riskHeatMap,
      config: { url },
      status,
      statusText,
    } = riskHeatMapResponse as AxiosResponse<RiskHeatMap>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getRiskHeatMapSuccess(riskHeatMap));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getRiskHeatMapFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchUpdateProjectExpirationDateRequest(action: Action): Generator {
  const {
    customerProjectId,
    project_expiration_date,
    goToListing
  } = action.payload as ProjectExpirationDatePayloadApi;

  try {
    const customerProjectExpirationDateResponse: AxiosResponse<any> | unknown = yield call(
      api,
      'PUT',
      `/controls/customers-projects/${customerProjectId}/update-project-expiration-date/`,
      { project_expiration_date }
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerProjectExpirationDateResponse as AxiosResponse<any>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(updateProjectExpirationDateSuccess());

    if (goToListing)
      yield put(getCustomerProjectRequest());
    else
      yield put(getCustomerProjectDetailRequest(customerProjectId));

    toast.success('Data de expiração alterada com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(updateProjectExpirationDateFailure());

    toast.success('Erro ao alterar a data de expiração.');
  }
}

function* fetchProjectCustomerDetail(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjectsDetailActionPayload;

  try {
    const projectSettingsEditingResponse: AxiosResponse<CustomerProjectDetail> | unknown = yield call(
      api,
      'GET',
      `controls/customers-projects/${customerProjectId}/detail-customer/`,
      {}
    );

    const {
      data: projectCustomersDetail,
      config: { url },
      status,
      statusText,
    } = projectSettingsEditingResponse as AxiosResponse<CustomerProjectDetail>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getProjectCustomerDetailSuccess(projectCustomersDetail));

  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getProjectCustomerDetailFailure());
  }
}

function* fetchAllCustomersProjectsCustomer(): Generator {
  try {
    const customerProjectResponse: AxiosResponse<CustomersProjectsCustomer> | unknown = yield call(
      api,
      'GET',
      '/controls/customers-projects-customer/',
      {}
    );

    const {
      data: customersProjectsCustomer,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<CustomersProjectsCustomer>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCustomerProjectCustomerSuccess(customersProjectsCustomer));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerProjecCustomertFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchGetEnvAssessmentCustomerProjectRequest(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjectQuestionnairePayload;
  try {
    const envAssessmentCustomerProjectResponse: AxiosResponse<AllEnvAssessmentCustomerProject> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${customerProjectId}/env-assessments/`,
      {}
    );


    const {
      data: allEnvAssessmentCustomerProject,
      config: { url },
      status,
      statusText,
    } = envAssessmentCustomerProjectResponse as AxiosResponse<AllEnvAssessmentCustomerProject>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getEnvAssessmentCustomerProjectSuccess(allEnvAssessmentCustomerProject));
  } catch (error) {
    const currentError = error as AxiosError;

    if (currentError.response?.status === 403) {
      console.warn('Acesso negado (403) ao buscar as avaliações ambientais.');
    } else {
      yield put(getLastApiFetchDataFailure(currentError));
      yield put(getEnvAssessmentCustomerProjectFailure());
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchPutIdentifiedQuantity(action: Action): Generator {
  const { id, identified_quantity, customerProjectId } = action.payload as IdentifiedQuantityPayload;

  try {
    const editingResponse: AxiosResponse<any> | unknown = yield call(
      api,
      'PUT',
      `controls/env-assessment-customer-project/${id}/`,
      {
        identified_quantity,
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = editingResponse as AxiosResponse<any>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(putIdentifiedQuantitySuccess());
    yield put(getEnvAssessmentCustomerProjectRequest(customerProjectId));

    toast.success('Edição da quantidade identificada foi realizado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(putIdentifiedQuantityFailure());

    toast.error(
      currentError?.response?.data?.detail ?? 'Tentativa de edição da quantidade identificada falhou.'
    );
  }
}

function* fetchPutAuditedInterviwees(action: Action): Generator {
  const { customerProjectQuestionnaireId, customerProjectId, audit_interviewees, customers_users, linkUser } = action.payload as LinkAuditedIntervieweesPayload;
  try {
    const auditedIntervieweesResponse: AxiosResponse<LinkAuditedIntervieweesResponsePayload> | unknown = yield call(
      api,
      'PUT',
      linkUser ? `/controls/customer-project-questionnaire/${customerProjectQuestionnaireId}/update-customers-users/` : `/controls/customer-project-questionnaire/${customerProjectQuestionnaireId}/update-audit-interviewees/`,
      linkUser ? { customers_users } : { audit_interviewees }
    );

    const {
      config: { url },
      status,
      statusText,
    } = auditedIntervieweesResponse as AxiosResponse<LinkAuditedIntervieweesResponsePayload>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    if (status === 200 || status === 201) {
      yield put(putLinkAuditedIntervieweesSuccess());
      yield put(getNewCustomerProjectQuestionnairesRequest(customerProjectId));

      if(linkUser){
        toast.success('Usuário(s) vinculado(s) com sucesso.');
      } else {
        toast.success('Entrevistado(s) vinculado(s) com sucesso.');
      }      
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(putLinkAuditedIntervieweesFailure());

    if(linkUser){
      toast.error('Falha ao vincular o(s) usuário(s).');  
    } else {
      toast.error('Falha ao vincular o(s) entrevistado(s).');
    }    
  }
}

export function* customerProjectSagas() {
  yield all([
    takeLatest(SEND_INTERVIEW_EMAIL_REQUEST, sendInterviewInviteEmailRequest),
    takeLatest(EDIT_APROVE_PDF_REQUEST, fetchReportsApprovePdfProject),
    takeLatest(GET_ALL_REPORTS_PROJECT_CUSTOMER_PROJECT_REQUEST, fetchReportsProjectCustomerProject),
    takeLatest(REMOVE_QUESTIONNAIRE_EVIDENCES_REQUEST, removeCustomersProjectsQuestionnaireEvidencesRequest),
    takeLatest(GET_ORGANOGRAM_CUSTOMER_PROJECT_REQUEST, fetchOrganogramCustomersProjectsRequest),
    takeLatest(EDIT_QUESTIONNAIRE_ANSWERS_REQUEST, editQuestionnaireAnswerRequestSagas),
    takeLatest(GET_ALL_CUSTOMER_PROJECT_REQUEST, fetchAllCustomersProjects),
    takeLatest(CREATE_NEW_CUSTOMER_PROJECT_REQUEST, fetchCreateNewCustomersProjects),
    takeLatest(GET_ALL_CONTROL_PROJECT_TYPE_REQUEST, fetchAllControlProjectTypeRequest),
    takeLatest(GET_ALL_CONTROL_CUSTOMERS_REQUEST, fetchAllControlCustomersRequest),
    takeLatest(GET_ALL_ANALYSTS_REQUEST, fetchAllAnalystsRequest),
    takeLatest(EDIT_CUSTOMER_PROJECT_REQUEST, fetchToCustomerProjectEditing),
    takeLatest(GET_CUSTOMER_PROJECT_DETAIL_REQUEST, fetchCustomerProjectDetail),
    takeLatest(EDIT_QUESTIONNAIRES_INTERWEES_REQUEST, fetchEditQuestionnaireInterviwees),
    takeLatest(ANALYST_ANSWERING_QUESTION_REQUEST, fetchCreateAnalystAnsweringQuestionnaire),
    takeLatest(GET_CUSTOMER_QUESTIONNAIRE_ANSWER_DETAIL_REQUEST, fetchCustomerQuestionnaireAnswerProjectDetail),
    takeLatest(DELETE_CUSTOMER_PROJECT_QUESTIONNAIRE_REQUEST, fetchDeleteCustomerProjectQuestionnaire),
    takeLatest(CHECKLIST_ACTIVITY_ANSWER_REQUEST, fetchCreateChecklistActivityAnswerRequest),
    takeLatest(CHECKLIST_ACTIVITY_EVIDENCES_REQUEST, fetchCreateChecklistActivityEvidencesRequest),
    takeLatest(EDIT_ACTIVITY_REGISTRATIONS_REQUEST, fetchEditActivityRegistrationsRequest),
    takeLatest(CHECKLIST_QUESTION_ANSWER_REQUEST, fetchCreateChecklistQuestionAnswerRequest),
    takeLatest(GET_ACTIVITY_DETAILS_REQUEST, fetchChecklistActivityDetails),
    takeLatest(GET_QUESTION_DETAILS_REQUEST, fetchChecklistQuestionDetails),
    takeLatest(GET_CUSTOMER_PROJECT_CHECKLISTS_REQUEST, fetchCustomersProjectsChecklistsRequest),
    takeLatest(EDIT_ACTIVITY_ANSWER_REQUEST, fetchEditChecklistActivityAnswerRequest),
    takeLatest(EDIT_ACTIVITY_ANSWER_EVIDENCES_REQUEST, fetchEditChecklistActivityAnswerAndEvidenceRequest),
    takeLatest(EDIT_QUESTION_ANSWER_EVIDENCES_REQUEST, editChecklistQuestionEvidencesRequest),
    takeLatest(GET_CUSTOMER_PROJECT_THREATS_REQUEST, fetchCustomersProjectsThreatsRequest),
    takeLatest(GET_CUSTOMER_PROJECT_SCORE_CARD_REQUEST, fetchCustomersProjectsScoreCardRequest),
    takeLatest(DELETE_CUSTOMER_PROJECT_CROWN_JEWEL_REQUEST, fetchDeleteCustomerProjectCrownJewelRequest),
    takeLatest(EDIT_CUSTOMER_PROJECT_CSF_CORE_REQUEST, fetchEditCustomerProjectCSFcoreRequest),
    takeLatest(EDIT_CUSTOMER_PROJECT_CROWN_JEWEL_REQUEST, fetchEditCustomerProjectCrownJewelRequest),
    takeLatest(GET_THREATS_CUSTOMER_PROJECT_DETAIL_REQUEST, fetchThreatsCustomersDetailProject),
    takeLatest(GET_ALL_REPORTS_TEXTS_CUSTOMER_PROJECT_REQUEST, fetchAllReportsTextsCustomerProject),
    takeLatest(SEND_ORGANOGRAM_REQUEST, fetchCreateOrganogramRequest),
    takeLatest(SEND_QUESTIONNAIRE_EVIDENCES_REQUEST, fetchQuestionnaireAnswersEvidencesRequest),
    takeLatest(EDIT_ACTIVITY_REGISTRATIONS_REQUEST_DETAIL, fetchEditActivityRegistrationsRequestDetail),
    takeLatest(EDIT_ACTIVITY_REGISTRATIONS_REQUEST_DETAIL_TWO, fetchEditActivityRegistrationsRequestDetailTwo),
    takeLatest(EDIT_CUSTOMER_PROJECT_STATUS_REQUEST, editCustomerProjectStatusRequest),
    takeLatest(GET_FILTERED_CUSTOMER_PROJECT_REQUEST, fetchSearchFilteredCustomerProjects),

    takeLatest(GET_CUSTOMER_PROJECT_QUESTIONNAIRES_REQUEST, fetchCustomersProjectsQuestionnairesRequest),
    takeLatest(NEW_GET_CUSTOMER_PROJECT_QUESTIONNAIRES_REQUEST, fetchNewCustomersProjectsQuestionnairesRequest),
    takeLatest(GET_CUSTOMER_PROJECT_QUESTIONNAIRES_DETAIL_REQUEST, fetchCustomersProjectsQuestionnairesDetailRequest),
    takeLatest(PUT_PROJECT_STATUS_CHANGE_REQUEST, fetchPutProjectStatusChangeRequest),
    takeLatest(GET_SCORE_CARD_CHART_REQUEST, fetchCustomersProjectsScoreCardRadarChatRequest),
    takeLatest(GET_RISK_DONUT_CHART_REQUEST, fetchCustomersProjectsRiskDonutChartRequest),
    takeLatest(GET_RISK_HEAT_MAP_REQUEST, fetchCustomersProjectsRiskHeatMapRequest),
    takeLatest(UPDATE_PROJECT_EXPIRATION_DATE_REQUEST, fetchUpdateProjectExpirationDateRequest),
    takeLatest(GET_PROJECT_CUSTOMER_DETAIL_REQUEST, fetchProjectCustomerDetail),
    takeLatest(GET_ALL_CUSTOMER_PROJECT_CUSTOMER_REQUEST, fetchAllCustomersProjectsCustomer),
    takeLatest(GET_ALL_ENV_ASSESSMENT_CUSTOMERPROJECT_REQUEST, fetchGetEnvAssessmentCustomerProjectRequest),
    takeLatest(PUT_IDENTIFIED_QUANTITY_REQUEST, fetchPutIdentifiedQuantity),
    takeLatest(PUT_AUDITED_INTERWEES_REQUEST, fetchPutAuditedInterviwees),
  ]);
}
