import { lazy } from 'react';

const LoginPage = lazy(() =>
  import('pages/Login').then(module => ({ default: module.Login }))
);

const TwoFactorAuthenticationPage = lazy(() =>
  import('pages/TwoFactorAuthentication').then(module => ({
    default: module.TwoFactorAuthentication,
  }))
);

const MultipleFactorAuthenticationPage = lazy(() =>
  import('pages/MultipleFactorAuthentication').then(module => ({
    default: module.MultipleFactorAuthentication,
  }))
);

const ResetPasswordPage = lazy(() =>
  import('pages/ResetPassword').then(module => ({
    default: module.ResetPassword,
  }))
);

const ForgotPasswordPage = lazy(() =>
  import('pages/ForgotPassword').then(module => ({
    default: module.ForgotPassword,
  }))
);

const UsersPage = lazy(() =>
  import('pages/Users').then(module => ({ default: module.Users }))
);

const ProfilePage = lazy(() =>
  import('pages/Profile').then(module => ({ default: module.Profile }))
);

const ThreatsPage = lazy(() =>
  import('pages/Threats').then(module => ({ default: module.Threats }))
);

const ThreatStep2Registration = lazy(() =>
  import('pages/Threats/ThreatStep2Registration').then(module => ({ default: module.ThreatStep2Registration }))
);

const ThreatsVizualization = lazy(() =>
  import('pages/Threats/ThreatsVizualization').then(module => ({ default: module.ThreatsVizualization }))
);

const ThreatsEdition = lazy(() =>
  import('pages/Threats/ThreatEdition').then(module => ({ default: module.ThreatsEdition }))
);

const RegisterPasswordPage = lazy(() =>
  import('pages/RegisterPassword').then(module => ({
    default: module.RegisterPassword,
  }))
);

const CustomersPage = lazy(() =>
  import('pages/Customers').then(module => ({
    default: module.Customers,
  }))
);

const CustomerDetails = lazy(() =>
  import('pages/Customers/InfoEdition').then(module => ({
    default: module.InfoEdition,
  }))
);

const BusinessSegmentationPage = lazy(() =>
  import('pages/BusinessSegmentation').then(module => ({
    default: module.BusinessSegmentation,
  }))
);

const VulnerabilitiesPage = lazy(() =>
  import('pages/Vulnerabilities').then(module => ({
    default: module.Vulnerabilities,
  }))
);

const VulnerabilityVisualizationPage = lazy(() =>
  import('pages/VulnerabilityVisualization').then(module => ({
    default: module.VulnerabilityVisualization,
  }))
);

const VulnerabilityRegistrationPage = lazy(() =>
  import('pages/VulnerabilityStep3Registration').then(module => ({
    default: module.VulnerabilityStep3Registration,
  }))
);

const VulnerabilityEditingPage = lazy(() =>
  import('pages/VulnerabilityEditing').then(module => ({
    default: module.VulnerabilityEditing,
  }))
);

const RiskFactorPage = lazy(() =>
  import('pages/RiskFactor').then(module => ({
    default: module.RiskFactor,
  }))
);

const RiskFactorVisualizationPage = lazy(() =>
  import('pages/RiskFactorVisualization').then(module => ({
    default: module.RiskFactorVisualization,
  }))
);

const RiskFactorRegistrationPage = lazy(() =>
  import('pages/RiskFactorStep2Registration').then(module => ({
    default: module.RiskFactorStep2Registration,
  }))
);

const RiskFactorEditingPage = lazy(() =>
  import('pages/RiskFactorEditing').then(module => ({
    default: module.RiskFactorEditing,
  }))
);

const RiskStep4Registration = lazy(() =>
  import('pages/RiskStep4Registration').then(module => ({
    default: module.RiskStep4Registration,
  }))
);

const RiskPage = lazy(() =>
  import('pages/RiskPage').then(module => ({
    default: module.RiskPage,
  }))
);

const RiskVizualization = lazy(() =>
  import('pages/RiskPage/RiskVizualization').then(module => ({
    default: module.RiskVizualization,
  }))
);

const RiskEdition = lazy(() =>
  import('pages/RiskPage/RiskEdition').then(module => ({
    default: module.RiskEdition,
  }))
);

const RiskEditionVinculation = lazy(() =>
  import('pages/RiskPage/RiskEditionVinculation').then(module => ({
    default: module.RiskEditionVinculation,
  }))
);

const RiskDamageRegistration = lazy(() =>
  import('pages/RiskPage/RiskDamageRegistration').then(module => ({
    default: module.RiskDamageRegistration,
  }))
);

const ControlsPage = lazy(() =>
  import('pages/Control').then(module => ({
    default: module.ControlPage,
  }))
);

const ControlDetail = lazy(() =>
  import('pages/Control/ControlDetail').then(module => ({
    default: module.ControlDetail,
  }))
);

const CustomerProject = lazy(() =>
  import('pages/ProjectClient').then(module => ({
    default: module.CustomerProjectPage,
  }))
);

const CustomerProjectStep2Registration = lazy(() =>
  import('pages/ProjectClient/CustomerProjectRegistration').then(module => ({
    default: module.CustomerProjectStep2Registration,
  }))
);

const CustomerProjectDetail = lazy(() =>
  import('pages/ProjectClient/CustomerProjectDetails').then(module => ({
    default: module.CustomerProjectDetails,
  }))
);

const Step5ControlAddition = lazy(() =>
  import('pages/Control/Step5ControlAddition').then(module => ({
    default: module.Step5ControlAddition,
  }))
);

const ControlEdition = lazy(() =>
  import('pages/Control/ControlEdition').then(module => ({
    default: module.ControlEdition,
  }))
);

const DamagePage = lazy(() =>
  import('pages/Damage').then(module => ({
    default: module.Damage,
  }))
);

const DamageVisualizationPage = lazy(() =>
  import('pages/DamageVisualization').then(module => ({
    default: module.DamageVisualization,
  }))
);

const DamageRegistrationPage = lazy(() =>
  import('pages/DamageStep2Registration').then(module => ({
    default: module.DamageStep2Registration,
  }))
);

const DamageEditingPage = lazy(() =>
  import('pages/DamageEditing').then(module => ({
    default: module.DamageEditing,
  }))
);

const PageNotFound404Page = lazy(() =>
  import('pages/NotFound404').then(module => ({ default: module.NotFound404 }))
);

const UnauthorizedPage = lazy(() =>
  import('pages/Unauthorized').then(module => ({
    default: module.Unauthorized,
  }))
);

const ChecklistsPage = lazy(() =>
  import('pages/Checklist').then(module => ({
    default: module.Checklists
  }))
);

const ChecklistVisualizationPage = lazy(() =>
  import('pages/Checklist/ChecklistVisualization').then(module => ({
    default: module.ChecklistVisualization,
  }))
);

const ChecklistEditingPage = lazy(() =>
  import('pages/Checklist/ChecklistEditing').then(module => ({
    default: module.ChecklistEditing,
  }))
);

const ChecklistRegistrationPage = lazy(() =>
  import('pages/Checklist/ChecklistStep2Registration').then(module => ({
    default: module.ChecklistStep2Registration,
  }))
);

const ControlTypesPage = lazy(() =>
  import('pages/ControlTypes').then(module => ({
    default: module.ControlType,
  }))
);

const ActivitiesPage = lazy(() =>
  import('pages/Activities').then(module => ({
    default: module.Activitie,
  }))
);

const QuestionsPage = lazy(() =>
  import('pages/Question').then(module => ({
    default: module.Questions,
  }))
);

const QuizPage = lazy(() =>
  import('pages/Quiz').then(module => ({
    default: module.Quiz,
  }))
);

const QuizRegistrationPage = lazy(() =>
  import('pages/Quiz/QuizAddition').then(module => ({
    default: module.QuizAddition,
  }))
);

const QuizAndQuestionsBonds = lazy(() =>
  import('pages/Quiz/QuizAndQuestionsBond').then(module => ({
    default: module.QuizAndQuestionsBond
  }))
);

const QuizVisualizationPage = lazy(() =>
  import('pages/Quiz/QuizVisualization').then(module => ({
    default: module.QuizVisualization
  }))
);

const QuizReview = lazy(() =>
  import('pages/Quiz/QuizAddition/QuestionReview').then(module => ({
    default: module.QuestionReview
  }))
);

const QuestionVisualizationPage = lazy(() =>
  import('pages/Question/QuestionVisualization').then(module => ({
    default: module.QuestionVisualization,
  }))
);

const CustomerProjectQuestionnaireVisualizationPage = lazy(() =>
  import('pages/ProjectClient/CustomerProjectDetails/QuestionnaireVisualization').then(module => ({
    default: module.QuestionnaireVisualization,
  }))
);

const QuestionnaireAnswerEditRegistrations = lazy(() =>
  import('pages/ProjectClient/CustomerProjectDetails/QuestionnaireAnswerEditRegistrations').then(module => ({
    default: module.QuestionnaireAnswerEditRegistrations,
  }))
);

const QuestionnaireAnalystAnswer = lazy(() =>
  import('pages/ProjectClient/CustomerProjectDetails/QuestionnaireAnalystAnswer').then(module => ({
    default: module.QuestionnaireAnalystAnswer,
  }))
);

const QuestionnaireAddQuestionnaire = lazy(() =>
  import('pages/ProjectClient/CustomerProjectDetails/QuestionnaireAddQuestionnaire').then(module => ({
    default: module.QuestionnaireAddQuestionnaire,
  }))
);

const SendActivityStep2Registrations = lazy(() =>
  import('pages/ProjectClient/CustomerProjectDetails/ChecklistAndActivity/SendActivityStep2Registrations').then(module => ({
    default: module.SendActivityStep2Registrations,
  }))
);

const AnswerRegistrations = lazy(() =>
  import('pages/ProjectClient/CustomerProjectDetails/AnswerRegistrations').then(module => ({
    default: module.AnswerRegistrations,
  }))
);


const ChecklistActivityDetail = lazy(() =>
  import('pages/ProjectClient/CustomerProjectDetails/ChecklistAndActivity/ChecklistActivityDetail').then(module => ({
    default: module.ChecklistActivityDetailPage,
  }))
);

const ChecklistAddition = lazy(() =>
  import('pages/ProjectClient/CustomerProjectDetails/ChecklistAndActivity/ChecklistAddition').then(module => ({
    default: module.ChecklistAddition,
  }))
);

const ChecklistQuestionDetail = lazy(() =>
  import('pages/ProjectClient/CustomerProjectDetails/ChecklistAndActivity/ChecklistQuestionDetail').then(module => ({
    default: module.ChecklistQuestionDetail,
  }))
);

const CustomerProjectRiskVisualization = lazy(() =>
  import('pages/ProjectClient/CustomerProjectDetails/RiskCustomerProject/CustomerProjectRiskVisualization').then(module => ({
    default: module.CustomerProjectRiskVisualization,
  }))
);

const CustomerProjectRiskEditionRegistrations = lazy(() =>
  import('pages/ProjectClient/CustomerProjectDetails/RiskCustomerProject/CustomerProjectRiskEdition/CustomerProjectRiskEditionRegistrations').then(module => ({
    default: module.CustomerProjectRiskEditionRegistrations,
  }))
);

const RecommendationCustomerProjectEdition = lazy(() =>
  import('pages/ProjectClient/CustomerProjectDetails/RiskCustomerProject/CustomerProjectRiskEdition/RecommendationCustomerProjectEdition').then(module => ({
    default: module.RecommendationCustomerProjectEdition,
  }))
);

const QuestionRegistrationPage = lazy(() =>
  import('pages/Question/QuestionStep3Registration').then(module => ({
    default: module.QuestionStep3Registration,
  }))
);

const CrownJewelsPage = lazy(() =>
  import('pages/CrownJewels').then(module => ({
    default: module.CrownJewel,
  }))
);

const ProjectSettingsPage = lazy(() =>
  import('pages/ProjectSettings').then(module => ({
    default: module.ProjectSettingss
  }))
);

const ProjectSettingsStep2Registration = lazy(() =>
  import('pages/ProjectSettings/ProjectSettingsStep2Registration').then(module => ({
    default: module.ProjectSettingsStep2Registration
  }))
);

const ProjectSettingsVisualization = lazy(() =>
  import('pages/ProjectSettings/ProjectSettingsVisualization').then(module => ({
    default: module.ProjectSettingsVisualization
  }))
);

const ProjectSettingsEdit = lazy(() =>
  import('pages/ProjectSettings/ProjectSettingsEdit').then(module => ({
    default: module.ProjectSettingsEdit
  }))
);

const QuestionnaireEditAnswer = lazy(() =>
  import('pages/ProjectClient/CustomerProjectDetails/QuestionnaireEditAnswer').then(module => ({
    default: module.QuestionnaireEditAnswer
  }))
);


const CustomerProjectThreatVisualization = lazy(() =>
  import('pages/ProjectClient/CustomerProjectDetails/ThreatCustomerProject/ThreatCustomerProjectVisualization').then(module => ({
    default: module.CustomerProjectThreatVisualization
  }))
);

const CustomerProjectControlVisualization = lazy(() =>
  import('pages/ProjectClient/CustomerProjectDetails/CustomerProjectControls/CustomerControl/ControlCustomerProjectVisualization').then(module => ({
    default: module.CustomerProjectControlVisualization
  }))
);

const ControlRecommendationsVisualization = lazy(() =>
  import('pages/ProjectClient/CustomerProjectDetails/CustomerProjectControls/ControlRecommendations/ControlRecommendationsVisualization').then(module => ({
    default: module.ControlRecommendationsVisualization
  }))
);

const FormQuestionnaireNIST = lazy(() =>
  import('pages/ProjectClient/CustomerProjectDetails/QuestionnaireContainer/QuestionnaireContainerComponent/QuestionnaireNISTContainer/FormQuestionnaireNIST').then(module => ({
    default: module.FormQuestionnaireNIST,
  }))
);

const MarketAveragePage = lazy(() =>
  import('pages/MarketAverage').then(module => (
    {
      default: module.MarketAverage
    }
  ))
);

const MarketAverageDetails = lazy(() =>
  import('pages/MarketAverage/MarketAverageDetails').then(module => (
    {
      default: module.MarketAverageDetails,
    }
  ))
);

const FrameworksPage = lazy(() =>
  import('pages/Frameworks').then(module => ({
    default: module.Frameworks,
  }))
);

const StandardsPage = lazy(() =>
  import('pages/Standards').then(module => ({
    default: module.Standards,
  }))
);

const FrameworksVisualizationPage = lazy(() =>
  import('pages/FrameworksVisualization').then(module => ({
    default: module.FrameworksVisualization,
  }))
);

const StandardsVisualizationPage = lazy(() =>
  import('pages/Standards/StandardsVisualization').then(module => ({
    default: module.StandardsVisualization,
  }))
);

const FrameworkCSFCoreStandardsPage = lazy(() =>
  import('pages/Frameworks/FrameworkCSFCoreStandards').then(module => ({
    default: module.FrameworkCSFCoreStandards,
  }))
);

const FrameworkCSFCoreControlsPage = lazy(() =>
  import('pages/Frameworks/FrameworkCSFCoreControls').then(module => ({
    default: module.FrameworkCSFCoreControls,
  }))
);

const ProjectsForAnonymizationPage = lazy(() =>
  import('pages/ProjectsForAnonymization').then(module => ({
    default: module.ProjectsForAnonymization,
  }))
);

const AnonymizationDetailsPage = lazy(() =>
  import('pages/ProjectsForAnonymization/AnonymizationDetails').then(module => ({
    default: module.AnonymizationDetails,
  }))
);

const ApplicationLogsPage = lazy(() =>
  import('pages/ApplicationLogs').then(module => ({
    default: module.ApplicationLogs
  }))
);

const ApplicationLogsDetailsPage = lazy(() =>
  import('pages/ApplicationLogs/ApplicationLogDetails').then(module => ({
    default: module.ApplicationLogDetails
  }))
);

const SolutionsPage = lazy(() =>
  import('pages/Solutions').then(module => ({
    default: module.Solutions
  }))
);

const SolutionControlsLinkPage = lazy(() =>
  import('pages/Solutions/SolutionControlsLink').then(module => ({
    default: module.SolutionControlsLink
  }))
);

const ControlSolutionsVisualizationPage = lazy(() =>
  import('pages/ProjectClient/CustomerProjectDetails/CustomerProjectControls/ControlSolutions/ControlSolutionsVisualization').then(module => ({
    default: module.ControlSolutionsVisualization
  }))
);

const FrameworkCSFCoreItensPage = lazy(() =>
  import('pages/Frameworks/FrameworkCSFCoreItens').then(module => ({
    default: module.FrameworkCSFCoreItens,
  }))
);

const UserProfilesPage = lazy(() =>
  import('pages/UserProfiles').then(module => ({
    default: module.UserProfiles,
  }))
);

const UserProfileDetailPage = lazy(() =>
  import('pages/UserProfileDetail').then(module => ({
    default: module.UserProfileDetail,
  }))
);

const GroupsPage = lazy(() =>
  import('pages/Groups').then(module => ({
    default: module.Groups,
  }))
);

const GroupDetailPage = lazy(() =>
  import('pages/GroupDetail').then(module => ({
    default: module.GroupDetail,
  }))
);

const EditCSFCoreSolutionsPage = lazy(() =>
  import('pages/ProjectClient/CustomerProjectDetails/CustomerProjectControls/CsfCoreSolutions/EditCSFCoreSolutions').then(module => ({
    default: module.EditCSFCoreSolutions
  }))
);

const MedicalRecordsPage = lazy(() =>
  import('pages/MedicalRecords').then(module => ({
    default: module.MedicalRecords,
  }))
);

const SizingTypesPage = lazy(() => 
  import('pages/SizingTypes').then(module => ({
    default: module.SizingTypes,
  }))
);

const SubControlPricing = lazy(() =>
  import('pages/Control/SubControlsDetail').then(module => ({
    default: module.SubControlsDetail,
  }))
);

const ProductDetailPage = lazy(() =>
  import('pages/Control/ProductDetail').then(module => ({
    default: module.ProductDetail,
  }))
);

const ScorecardRankingPage = lazy(() => 
  import('pages/MarketAverage/ScorecardRanking').then(module => ({default: module.ScorecardRanking}))
);

export {
  LoginPage,
  TwoFactorAuthenticationPage,
  MultipleFactorAuthenticationPage,
  ResetPasswordPage,
  ForgotPasswordPage,
  UsersPage,
  ProfilePage,
  RegisterPasswordPage,
  BusinessSegmentationPage,
  VulnerabilitiesPage,
  VulnerabilityVisualizationPage,
  VulnerabilityRegistrationPage,
  VulnerabilityEditingPage,
  RiskFactorPage,
  RiskFactorVisualizationPage,
  RiskFactorRegistrationPage,
  RiskFactorEditingPage,
  DamagePage,
  DamageVisualizationPage,
  DamageRegistrationPage,
  DamageEditingPage,
  PageNotFound404Page,
  UnauthorizedPage,
  CustomersPage,
  CustomerDetails,
  ThreatsPage,
  ThreatsVizualization,
  ChecklistsPage,
  ChecklistRegistrationPage,
  ChecklistVisualizationPage,
  ChecklistEditingPage,
  ThreatStep2Registration,
  ThreatsEdition,
  ControlTypesPage,
  RiskStep4Registration,
  RiskPage,
  RiskDamageRegistration,
  RiskVizualization,
  QuestionsPage,
  QuizPage,
  QuizRegistrationPage,
  QuizAndQuestionsBonds,
  QuizVisualizationPage,
  QuizReview,
  QuestionVisualizationPage,
  QuestionRegistrationPage,
  RiskEdition,
  ActivitiesPage,
  ControlsPage,
  ControlDetail,
  Step5ControlAddition,
  CrownJewelsPage,
  ControlEdition,
  CustomerProject,
  CustomerProjectStep2Registration,
  CustomerProjectDetail,
  ProjectSettingsPage,
  ProjectSettingsStep2Registration,
  ProjectSettingsVisualization,
  ProjectSettingsEdit,
  RiskEditionVinculation,
  QuestionnaireAnalystAnswer,
  CustomerProjectQuestionnaireVisualizationPage,
  QuestionnaireAnswerEditRegistrations,
  QuestionnaireAddQuestionnaire,
  SendActivityStep2Registrations,
  ChecklistActivityDetail,
  ChecklistQuestionDetail,
  ChecklistAddition,
  AnswerRegistrations,
  CustomerProjectRiskVisualization,
  CustomerProjectRiskEditionRegistrations,
  RecommendationCustomerProjectEdition,
  CustomerProjectThreatVisualization,
  CustomerProjectControlVisualization,
  ControlRecommendationsVisualization,
  QuestionnaireEditAnswer,
  FormQuestionnaireNIST,
  MarketAveragePage,
  MarketAverageDetails,
  FrameworksPage,
  StandardsPage,
  FrameworksVisualizationPage,
  StandardsVisualizationPage,
  FrameworkCSFCoreStandardsPage,
  FrameworkCSFCoreControlsPage,
  ProjectsForAnonymizationPage,
  AnonymizationDetailsPage,
  ApplicationLogsPage,
  ApplicationLogsDetailsPage,
  SolutionsPage,
  SolutionControlsLinkPage,
  ControlSolutionsVisualizationPage,
  FrameworkCSFCoreItensPage,
  UserProfilesPage,
  UserProfileDetailPage,
  GroupsPage,
  GroupDetailPage,
  EditCSFCoreSolutionsPage,
  MedicalRecordsPage,
  SizingTypesPage,
  SubControlPricing,
  ProductDetailPage,
  ScorecardRankingPage,
};
