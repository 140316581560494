import type { Action } from 'store/types';

import { 
  MarketAverageActions, 
  MarketAverageDetail, 
  MarketScoreCardAverage, 
  ScoreCardRanking, 
  ScoreCardSectorGraph, 
  Sector, 
  SectorGraph
} from './types';


const {
  GET_SCORECARD_RANKING_REQUEST,
  GET_SCORECARD_RANKING_SUCCESS,
  GET_SCORECARD_RANKING_FAILURE,
  GET_ALL_MARKET_AVERAGE_REQUEST,
  GET_ALL_MARKET_AVERAGE_SUCCESS,
  GET_ALL_MARKET_AVERAGE_FAILURE,
  GET_MARKET_AVERAGE_DETAILS_REQUEST,
  GET_MARKET_AVERAGE_DETAILS_SUCCESS,
  GET_MARKET_AVERAGE_DETAILS_FAILURE,
  GET_SCORECARD_SECTOR_GRAPH_REQUEST,
  GET_SCORECARD_SECTOR_GRAPH_SUCCESS,
  GET_SCORECARD_SECTOR_GRAPH_FAILURE,
  GET_MARKET_SCORECARD_AVERAGE_REQUEST,
  GET_MARKET_SCORECARD_AVERAGE_SUCCESS,
  GET_MARKET_SCORECARD_AVERAGE_FAILURE,  
} = MarketAverageActions;

function getMarketAverageSectorsRequest(): Action {
  return {
    type: GET_ALL_MARKET_AVERAGE_REQUEST,
  };
}

function getMarketAverageSectorsSuccess(
  results: Sector[],
  count?: number,
  next?: boolean,
  previous?: boolean,
): Action {
  return {
    type: GET_ALL_MARKET_AVERAGE_SUCCESS,
    payload: {
      results,
      count,
      next,
      previous
    },
  };
}

function getMarketAverageSectorsFailure(): Action {
  return {
    type: GET_ALL_MARKET_AVERAGE_FAILURE,
  };
}

function getMarketScoreCardAverageRequest(): Action {
  return {
    type: GET_MARKET_SCORECARD_AVERAGE_REQUEST,
  };
}

function getMarketScoreCardAverageSuccess(
  marketScoreCardAverage: MarketScoreCardAverage
): Action {
  return {
    type: GET_MARKET_SCORECARD_AVERAGE_SUCCESS,
    payload: {
      marketScoreCardAverage
    },
  };
}

function getMarketScoreCardAverageFailure(): Action {
  return {
    type: GET_MARKET_SCORECARD_AVERAGE_FAILURE,
  };
}

function getMarketAverageDetailRequest(
  id: string,
  type: string
): Action {
  return {
    type: GET_MARKET_AVERAGE_DETAILS_REQUEST,
    payload: {
      id,
      type
    }
  };
}

function getMarketAverageDetailSuccess(
  marketAverageDetail: MarketAverageDetail
): Action {
  return {
    type: GET_MARKET_AVERAGE_DETAILS_SUCCESS,
    payload: {
      marketAverageDetail
    },
  };
}

function getMarketAverageDetailFailure(): Action {
  return {
    type: GET_MARKET_AVERAGE_DETAILS_FAILURE,
  };
}

function getScorecardRankingRequest(
  year: string,
  sectorId: string
): Action {
  return {
    type: GET_SCORECARD_RANKING_REQUEST,
    payload: {
      year,
      sectorId
    }
  };
}

function getScorecardRankingSuccess(
  scoreCardRanking: ScoreCardRanking[]
): Action {
  return {
    type: GET_SCORECARD_RANKING_SUCCESS,
    payload: {
      scoreCardRanking
    },
  };
}

function getScorecardRankingFailure(): Action {
  return {
    type: GET_SCORECARD_RANKING_FAILURE,
  };
}

function getScoreCardSectorGraphRequest(): Action {
  return {
    type: GET_SCORECARD_SECTOR_GRAPH_REQUEST,
  };
}

function getScoreCardSectorGraphSuccess(
  scoreCardSectorGraph: ScoreCardSectorGraph
): Action {
  return {
    type: GET_SCORECARD_SECTOR_GRAPH_SUCCESS,
    payload: {
      scoreCardSectorGraph
    },
  };
}

function getScoreCardSectorGraphFailure(): Action {
  return {
    type: GET_SCORECARD_SECTOR_GRAPH_FAILURE,
  };
}

export const marketAverageActionsFunctions = {
  getScorecardRankingRequest,
  getScorecardRankingSuccess,
  getScorecardRankingFailure,
  getMarketAverageDetailRequest,
  getMarketAverageDetailSuccess,
  getMarketAverageDetailFailure,
  getMarketAverageSectorsRequest,
  getMarketAverageSectorsSuccess,
  getMarketAverageSectorsFailure,
  getScoreCardSectorGraphRequest,
  getScoreCardSectorGraphSuccess,
  getScoreCardSectorGraphFailure,
  getMarketScoreCardAverageRequest,
  getMarketScoreCardAverageSuccess,
  getMarketScoreCardAverageFailure,  
};
