
export enum CustomerProjectNistActions {
  SEND_EVIDENCES_NIST_REQUEST = 'SEND_EVIDENCES_NIST_REQUEST',
  SEND_EVIDENCES_NIST_SUCCESS = 'SEND_EVIDENCES_NIST_SUCCESS',
  SEND_EVIDENCES_NIST_FAILURE = 'SEND_EVIDENCES_NIST_FAILURE',
  DELETE_EVIDENCES_NIST_REQUEST = 'DELETE_EVIDENCES_NIST_REQUEST',
  DELETE_EVIDENCES_NIST_SUCCESS = 'DELETE_EVIDENCES_NIST_SUCCESS',
  DELETE_EVIDENCES_NIST_FAILURE = 'DELETE_EVIDENCES_NIST_FAILURE',
  GET_EVIDENCES_DOWNLOAD_REQUEST = 'GET_EVIDENCES_DOWNLOAD_REQUEST',
  GET_EVIDENCES_DOWNLOAD_SUCCESS = 'GET_EVIDENCES_DOWNLOAD_SUCCESS',
  GET_EVIDENCES_DOWNLOAD_FAILURE = 'GET_EVIDENCES_DOWNLOAD_FAILURE',
  GET_CUSTOMER_PROJECT_NIST_REQUEST = ' GET_CUSTOMER_PROJECT_NIST_REQUEST',
  GET_CUSTOMER_PROJECT_NIST_SUCCESS = 'GET_CUSTOMER_PROJECT_NIST_SUCCESS',
  GET_CUSTOMER_PROJECT_NIST_FAILURE = 'GET_CUSTOMER_PROJECT_NIST_FAILURE',  
}

export type Alternatives = {
  id: string;
  peso: string;
  name: string;
}

export type CSFCoreModel = {
  id: string;
  function: string;
  friendly_id: string;
  category: string;
  subcategory: string;
  subcategory_ptbr: string;
  maturity_level: string;
  version: number;
  alternatives: Alternatives[];
  evidence_to_be_collected: string;
  risk_group: string;
  evidences: any[];
}

export type CSFCoreIdentify = {
  id: string;
  function: string;
  friendly_id: string;
  category: string;
  subcategory: string;
  subcategory_ptbr: string;
  maturity_level: string;
  version: number;
  alternatives: Alternatives[];
  evidence_to_be_collected: string;
}

export type CSFCoreProtect = {
  id: string;
  function: string;
  friendly_id: string;
  category: string;
  subcategory: string;
  subcategory_ptbr: string;
  maturity_level: string;
  version: number;
  alternatives: Alternatives[];
  evidence_to_be_collected: string;
}

export type CSFCoreDetect = {
  id: string;
  function: string;
  friendly_id: string;
  category: string;
  subcategory: string;
  subcategory_ptbr: string;
  maturity_level: string;
  version: number;
  alternatives: Alternatives[];
  evidence_to_be_collected: string;
}

export type CSFCoreRespond = {
  id: string;
  function: string;
  friendly_id: string;
  category: string;
  subcategory: string;
  subcategory_ptbr: string;
  maturity_level: string;
  version: number;
  alternatives: Alternatives[];
  evidence_to_be_collected: string;
}

export type CSFCoreRecover = {
  id: string;
  function: string;
  friendly_id: string;
  category: string;
  subcategory: string;
  subcategory_ptbr: string;
  maturity_level: string;
  version: number;
  alternatives: Alternatives[];
  evidence_to_be_collected: string;
}

export type Framework = {
  id: string;
  name: string;
  version: string;
  is_active: boolean;
}

export type CustomerProjectNist = {
  csf_core: {
    [key: string]: CSFCoreModel[],
  };
  framework: Framework;
  csfcoreOrdering: string[];
}

export type CustomerProjectNistState = {
  customerProjectNist: CustomerProjectNist;
}

export type CustomerProjectNistQuestionnaireIdPayload = {
  customerProjectId: string;
}

export type CustomerProjectNistEvidenceIdApiPayload = {
  evidenceId: string;
  evidenceName: string;
}

export type CustomerProjectNistEvidences = {
  [x: string]: any[];
};

export type CustomerProjectNistEvidencesPayload = {
  customerProjectId: string;
  csfcoreProjectId: string;
  evidences: CustomerProjectNistEvidences;
}

export type DeleteCustomerProjectNistEvidencePayload = {
  evidenceId: string;
}