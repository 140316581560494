import produce from 'immer';
import { Reducer } from 'redux';

import { MarketAverageActions, MarketAverageState } from './types';


const {
  GET_SCORECARD_RANKING_REQUEST,
  GET_SCORECARD_RANKING_SUCCESS,
  GET_SCORECARD_RANKING_FAILURE,
  GET_ALL_MARKET_AVERAGE_REQUEST,
  GET_ALL_MARKET_AVERAGE_SUCCESS,
  GET_ALL_MARKET_AVERAGE_FAILURE,
  GET_MARKET_AVERAGE_DETAILS_REQUEST,
  GET_MARKET_AVERAGE_DETAILS_SUCCESS,
  GET_MARKET_AVERAGE_DETAILS_FAILURE,
  GET_SCORECARD_SECTOR_GRAPH_REQUEST,
  GET_SCORECARD_SECTOR_GRAPH_SUCCESS,
  GET_SCORECARD_SECTOR_GRAPH_FAILURE,
  GET_MARKET_SCORECARD_AVERAGE_REQUEST,
  GET_MARKET_SCORECARD_AVERAGE_SUCCESS,
  GET_MARKET_SCORECARD_AVERAGE_FAILURE,  
} = MarketAverageActions;

const initialState: MarketAverageState = {
  marketAverageOfAllSectors: [],
  marketScoreCardAverage: {
    average_score_card_rating: '',
    from_number: 0,
    to_number: 0,
    average_final_score: 0,
  },
  marketAverageDetail: {
    id: '',
    name: '',
    average_final_score: 0,
    average_score_card_rating: {
      id: '',
      name: '',
      category: '',
      from: 0,
      to: 0,
    },
    projects: [],
    qtd_customers: 0,
    qtd_projects: 0,
    qtd_segments: 0,
    qtd_subsectors: 0,
  },
  scoreCardRanking: [],
  scoreCardSectorGraph: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  }
};

export const marketAverageReducer: Reducer<MarketAverageState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_MARKET_AVERAGE_REQUEST: {
        break;
      }

      case GET_ALL_MARKET_AVERAGE_SUCCESS: {
        const { results } = action.payload;
        draft.marketAverageOfAllSectors = results;
        break;
      }

      case GET_ALL_MARKET_AVERAGE_FAILURE: {
        draft.marketAverageOfAllSectors = [];
        break;
      }

      case GET_MARKET_SCORECARD_AVERAGE_REQUEST: {
        break;
      }

      case GET_MARKET_SCORECARD_AVERAGE_SUCCESS: {
        const { marketScoreCardAverage } = action.payload;
        draft.marketScoreCardAverage = marketScoreCardAverage;
        break;
      }

      case GET_MARKET_SCORECARD_AVERAGE_FAILURE: {
        draft.marketScoreCardAverage = {
          average_score_card_rating: '',
          from_number: 0,
          to_number: 0,
          average_final_score: 0,
        };
        break;
      }

      case GET_MARKET_AVERAGE_DETAILS_REQUEST: {
        break;
      }

      case GET_MARKET_AVERAGE_DETAILS_SUCCESS: {
        const { marketAverageDetail } = action.payload;
        draft.marketAverageDetail = marketAverageDetail;
        break;
      }

      case GET_MARKET_AVERAGE_DETAILS_FAILURE: {
        draft.marketAverageDetail = {
          id: '',
          name: '',
          average_final_score: 0,
          average_score_card_rating: {
            id: '',
            name: '',
            category: '',
            from: 0,
            to: 0,
          },
          projects: [],
          qtd_customers: 0,
          qtd_projects: 0,
          qtd_segments: 0,
          qtd_subsectors: 0,
        };
        break;
      }

      case GET_SCORECARD_RANKING_REQUEST:
        break;

      case GET_SCORECARD_RANKING_SUCCESS: {
        const { scoreCardRanking } = action.payload;
        draft.scoreCardRanking = scoreCardRanking;
        break;
      }

      case GET_SCORECARD_RANKING_FAILURE: {
        draft.scoreCardRanking = [];
        break;
      }

      case GET_SCORECARD_SECTOR_GRAPH_REQUEST:
        break;

      case GET_SCORECARD_SECTOR_GRAPH_SUCCESS: {
        const { scoreCardSectorGraph } = action.payload;
        draft.scoreCardSectorGraph = scoreCardSectorGraph;
        break;
      }

      case GET_SCORECARD_SECTOR_GRAPH_FAILURE: {
        draft.scoreCardSectorGraph = {
          count: 0,
          next: null,
          previous: null,
          results: [],
        };
        break;
      }

      default: {
        return draft;
      }
    }
  });
};
